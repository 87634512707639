var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pwaIos bubble" }, [
    _c("div", {
      staticClass: "pwaIos__overlay",
      on: {
        click: function ($event) {
          return _vm.$emit("closePWAModal")
        },
      },
    }),
    _c(
      "button",
      {
        staticClass: "text-xl absolute right-1 -top-2 p-2",
        staticStyle: { filter: "drop-shadow(0 1px 3px rgb(0 0 0 / 1))" },
        on: {
          click: function ($event) {
            return _vm.$emit("closePWAModal")
          },
        },
      },
      [_vm._v(" × ")]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showLong,
            expression: "!showLong",
          },
        ],
        staticClass: "w-full",
      },
      [
        _c("img", {
          staticClass: "w-16 rounded-xl mx-auto",
          attrs: {
            src: "/pwa/images/icons/icon-144x144.png",
            alt: "Virtual Rooms Logo",
          },
        }),
        _c("p", { staticClass: "w-full text-center text-lg mt-1 mb-3" }, [
          _vm._v("Virtual Rooms"),
        ]),
      ]
    ),
    _c("p", { staticClass: "text-sm" }, [
      _vm._v("Per aggiungere Virtual Rooms alla schermata iniziale:"),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showLong,
            expression: "!showLong",
          },
        ],
        staticClass: "pwaIos__short",
      },
      [_vm._m(0)]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLong,
            expression: "showLong",
          },
        ],
        staticClass: "pwaIos__long",
      },
      [
        _c("video", {
          ref: "pwaDemo",
          attrs: {
            src: "/img/shortcut-instructions.mp4",
            loop: "",
            muted: "",
            playsinline: "",
            autoplay: "",
          },
          domProps: { muted: true },
        }),
      ]
    ),
    _c("div", { staticClass: "flex justify-between mt-4" }, [
      _c(
        "button",
        {
          staticClass:
            "px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium border-b border-white text-white",
          on: {
            click: function ($event) {
              _vm.showLong = !_vm.showLong
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.showLong ? "Chiudere" : "Piú") + " dettagli ")]
      ),
      _c(
        "button",
        {
          staticClass:
            "px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium border-b border-white text-white",
          on: {
            click: function ($event) {
              return _vm.dontShowPWAModalAgain()
            },
          },
        },
        [_vm._v(" Non vedere più ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", { staticClass: "text-sm pl-2" }, [
        _vm._v(" - Clicca "),
        _c("img", {
          staticClass: "h-6 w-auto inline",
          staticStyle: {
            filter: "invert(100%)",
            "-webkit-filter": "invert(100%)",
          },
          attrs: {
            src: require("../../public/img/share-icon-ios.png"),
            alt: "iOS share icon",
          },
        }),
      ]),
      _c("li", { staticClass: "text-sm pl-2" }, [
        _vm._v(" - Clicca "),
        _c("b", [_vm._v("Aggiungi alla schermata ")]),
        _c("span", { staticClass: "inline-block" }, [
          _c("b", [_vm._v("Home")]),
          _c("img", {
            staticClass: "h-6 w-auto inline ml-1",
            staticStyle: {
              filter: "invert(100%)",
              "-webkit-filter": "invert(100%)",
            },
            attrs: {
              src: require("../../public/img/add-icon-ios.png"),
              alt: "iOS add icon",
            },
          }),
        ]),
      ]),
      _c("li", { staticClass: "pl-2 text-sm" }, [
        _vm._v("- Conferma su "),
        _c("b", [_vm._v("Aggiungi")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }