export default [
   //1
   {
      name: "avatar_figure_1",
      src: "img/figures/avatar_figure_1.png",
      gender: "m",
      slug: "M1",
      body: [
         {
            id: "5",
            src: "img/figures/avatar_figure_1/1.png",
         },
         {
            id: "1",
            src: "img/figures/avatar_figure_1/2.png",
         },
         {
            id: "2",
            src: "img/figures/avatar_figure_1/3.png",
         },
         {
            id: "3",
            src: "img/figures/avatar_figure_1/4.png",
         },
         {
            id: "4",
            src: "img/figures/avatar_figure_1/5.png",
         },
      ],
   },
   //2
   {
      name: "avatar_figure_2",
      src: "img/figures/avatar_figure_2.png",
      gender: "m",
      slug: "M2",
      body: [
         {
            id: "9",
            src: "img/figures/avatar_figure_2/1.png",
         },
         {
            id: "6",
            src: "img/figures/avatar_figure_2/2.png",
         },
         {
            id: "7",
            src: "img/figures/avatar_figure_2/3.png",
         },
         {
            id: "8",
            src: "img/figures/avatar_figure_2/4.png",
         },
         {
            id: "10",
            src: "img/figures/avatar_figure_2/5.png",
         },
      ],
   },
   //3
   {
      name: "avatar_figure_3",
      src: "img/figures/avatar_figure_3.png",
      gender: "m",
      slug: "M3",
      body: [
         {
            id: "15",
            src: "img/figures/avatar_figure_3/1.png",
         },
         {
            id: "11",
            src: "img/figures/avatar_figure_3/2.png",
         },
         {
            id: "12",
            src: "img/figures/avatar_figure_3/3.png",
         },
         {
            id: "13",
            src: "img/figures/avatar_figure_3/4.png",
         },
         {
            id: "14",
            src: "img/figures/avatar_figure_3/5.png",
         },
      ],
   },
   //4
   {
      name: "avatar_figure_4",
      src: "img/figures/avatar_figure_4.png",
      gender: "m",
      slug: "M4",
      body: [
         {
            id: "20",
            src: "img/figures/avatar_figure_4/1.png",
         },
         {
            id: "16",
            src: "img/figures/avatar_figure_4/2.png",
         },
         {
            id: "17",
            src: "img/figures/avatar_figure_4/3.png",
         },
         {
            id: "18",
            src: "img/figures/avatar_figure_4/4.png",
         },
         {
            id: "19",
            src: "img/figures/avatar_figure_4/5.png",
         },
      ],
   },
   //5
   {
      name: "avatar_figure_5",
      src: "img/figures/avatar_figure_5.png",
      gender: "m",
      slug: "M5",
      body: [
         {
            id: "25",
            src: "img/figures/avatar_figure_5/1.png",
         },
         {
            id: "21",
            src: "img/figures/avatar_figure_5/2.png",
         },
         {
            id: "22",
            src: "img/figures/avatar_figure_5/3.png",
         },
         {
            id: "23",
            src: "img/figures/avatar_figure_5/4.png",
         },
         {
            id: "24",
            src: "img/figures/avatar_figure_5/5.png",
         },
      ],
   },
   //6
   {
      name: "avatar_figure_6",
      src: "img/figures/avatar_figure_6.png",
      gender: "m",
      slug: "M6",
      body: [
         {
            id: "30",
            src: "img/figures/avatar_figure_6/1.png",
         },
         {
            id: "26",
            src: "img/figures/avatar_figure_6/2.png",
         },
         {
            id: "27",
            src: "img/figures/avatar_figure_6/3.png",
         },
         {
            id: "28",
            src: "img/figures/avatar_figure_6/4.png",
         },
         {
            id: "29",
            src: "img/figures/avatar_figure_6/5.png",
         },
      ],
   },
   //7
   {
      name: "avatar_figure_7",
      src: "img/figures/avatar_figure_7.png",
      gender: "f",
      slug: "F1",
      body: [
         {
            id: "35",
            src: "img/figures/avatar_figure_7/1.png",
         },
         {
            id: "31",
            src: "img/figures/avatar_figure_7/2.png",
         },
         {
            id: "32",
            src: "img/figures/avatar_figure_7/3.png",
         },
         {
            id: "33",
            src: "img/figures/avatar_figure_7/4.png",
         },
         {
            id: "34",
            src: "img/figures/avatar_figure_7/5.png",
         },
      ],
   },
   //8
   {
      name: "avatar_figure_8",
      src: "img/figures/avatar_figure_8.png",
      gender: "f",
      slug: "F2",
      body: [
         {
            id: "40",
            src: "img/figures/avatar_figure_8/1.png",
         },
         {
            id: "36",
            src: "img/figures/avatar_figure_8/2.png",
         },
         {
            id: "37",
            src: "img/figures/avatar_figure_8/3.png",
         },
         {
            id: "38",
            src: "img/figures/avatar_figure_8/4.png",
         },
         {
            id: "39",
            src: "img/figures/avatar_figure_8/5.png",
         },
      ],
   },
   //9
   {
      name: "avatar_figure_9",
      src: "img/figures/avatar_figure_9.png",
      gender: "f",
      slug: "F3",
      body: [
         {
            id: "45",
            src: "img/figures/avatar_figure_9/1.png",
         },
         {
            id: "41",
            src: "img/figures/avatar_figure_9/2.png",
         },
         {
            id: "42",
            src: "img/figures/avatar_figure_9/3.png",
         },
         {
            id: "43",
            src: "img/figures/avatar_figure_9/4.png",
         },
         {
            id: "44",
            src: "img/figures/avatar_figure_9/5.png",
         },
      ],
   },
   //10
   {
      name: "avatar_figure_10",
      src: "img/figures/avatar_figure_10.png",
      gender: "f",
      slug: "F4",
      body: [
         {
            id: "50",
            src: "img/figures/avatar_figure_10/1.png",
         },
         {
            id: "46",
            src: "img/figures/avatar_figure_10/2.png",
         },
         {
            id: "47",
            src: "img/figures/avatar_figure_10/3.png",
         },
         {
            id: "48",
            src: "img/figures/avatar_figure_10/4.png",
         },
         {
            id: "49",
            src: "img/figures/avatar_figure_10/5.png",
         },
      ],
   },
   //11
   {
      name: "avatar_figure_11",
      src: "img/figures/avatar_figure_11.png",
      gender: "f",
      slug: "F5",
      body: [
         {
            id: "55",
            src: "img/figures/avatar_figure_11/1.png",
         },
         {
            id: "51",
            src: "img/figures/avatar_figure_11/2.png",
         },
         {
            id: "52",
            src: "img/figures/avatar_figure_11/3.png",
         },
         {
            id: "53",
            src: "img/figures/avatar_figure_11/4.png",
         },
         {
            id: "54",
            src: "img/figures/avatar_figure_11/5.png",
         },
      ],
   },
   //12
   {
      name: "avatar_figure_12",
      src: "img/figures/avatar_figure_12.png",
      gender: "f",
      slug: "F6",
      body: [
         {
            id: "60",
            src: "img/figures/avatar_figure_12/1.png",
         },
         {
            id: "56",
            src: "img/figures/avatar_figure_12/2.png",
         },
         {
            id: "57",
            src: "img/figures/avatar_figure_12/3.png",
         },
         {
            id: "58",
            src: "img/figures/avatar_figure_12/4.png",
         },
         {
            id: "59",
            src: "img/figures/avatar_figure_12/5.png",
         },
      ],
   },
];
