<template>
  <div class="modal">
    <div class="modal__overlay" @click="$emit('closeModal')"></div>
    <div
      class="modal__container"
      v-if="entry"
      :class="{ in: opening, out: closing }"
    >
      <div class="modal-header">
        <slot name="header"></slot>
        <button class="modal__close-btn" v-if="!hideX" @click="closeHandler">
          &times;
        </button>
      </div>

      <div class="modal-body">
        <slot name="body"> </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",

  props: {
    hideX: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      entry: false,
      opening: false,
      closing: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.entry = true;
      this.opening = true;
    }, 110);
    setTimeout(() => {
      this.opening = false;
    }, 410);
  },

  methods: {
    closeHandler() {
      this.closing = true;
      setTimeout(() => {
        this.$emit("closeModal");
      }, 150);
    },
  },
};
</script>

<style lang="postcss" scoped>
.modal {
  @apply flex items-center justify-center h-full w-full fixed top-0 left-0 z-50;

  &.qr {
    top: 100%;
    @screen lg {
      top: 0;
      left: -100vw;
      width: 100vw;
    }
  }

  .modal__overlay {
    @apply bg-gray-900 bg-opacity-50 h-full w-full absolute;
  }

  .modal__container {
    width: 90%;
    height: fit-content;
    @apply bg-gray-900 relative p-4 space-y-2 rounded-xl;

    /* &.in {
      animation: openModal 0.15s ease-in forwards;
    }*/
    &.in {
      animation: openModal 0.1s ease-out forwards;
    }

    &.out {
      animation: closeModal 0.1s ease-out forwards;
    }

    @screen md {
      width: 60%;
      @apply p-6;
    }

    .modal-header {
      @apply relative w-full;

      h2 {
        max-width: 85%;
        @apply text-secondary text-lg uppercase font-semibold tracking-wide;

        @screen md {
          @apply text-2xl;
        }
      }

      .modal__close-btn {
        @apply absolute -top-4 -right-1 text-4xl m-0 p-2;
      }
    }
  }

  .modal-body {
    p {
      @apply w-full text-center;
    }
  }
}
</style>
