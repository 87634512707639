var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("MessagesContainer", {
        attrs: {
          keyboardHeight: _vm.keyboardHeight,
          msgs: _vm.msgs,
          scrollDown: _vm.triggerScroll,
          scrollBehavior: _vm.scrollBehavior,
          isUserBanned: _vm.isUserBanned,
          isSystemEnabled: _vm.isSystemEnabled,
          isRoomEnabled: _vm.isRoomEnabled,
          taHeight: _vm.taHeight,
          isLoading: _vm.isLoading,
        },
        on: {
          newMessages: _vm.moreMessagesHandler,
          "reaction-update": _vm.reactionUpdateHandler,
        },
      }),
      !_vm.isUserBanned && _vm.isRoomEnabled && _vm.isSystemEnabled
        ? _c("TypingArea", {
            attrs: { moreMessages: _vm.moreMessages },
            on: {
              "new-messages-click": _vm.newMessagesClickHandler,
              "send-message": _vm.sendMessageHandler,
              typingAreaHeight: _vm.typingAreaHeightHandler,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }