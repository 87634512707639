<template>
  <div
    class="menu-container"
    id="menu-container"
    :class="{ 'is-closed': !isOpen }"
    v-hammer:swipe.up="menuClose"
  >
    <QRCode v-if="showModal" @close="showModal = false" />
    <div class="w-fit xs:w-1/3 lg:w-fit mx-auto">
      <button
        class="flex justify-center bg-gray-800 rounded-full p-1 h-20 w-20 lg:w-26 lg:h-26 mx-auto cursor-pointer relative"
        @click="imgClickHandler"
        :class="{ 'pointer-events-none': inGame }"
        style="filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))"
      >
        <img
          :src="showAvatar"
          class="object-cover w-full h-full rounded-full"
          id="avatar"
          @error="replaceByDefault"
          alt="User avatar"
        />
        <svg
          v-if="!inGame"
          class="h-6 w-6 absolute right-0 bottom-0 z-50 bg-gray-800 rounded-full"
          style="filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#fff"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <!-- </router-link> -->
      </button>
      <h1
        class="py-2 xs:py-1.5 lg:py-2 uppercase text-primary text-sm font-bold text-center w-full lg:text-xl truncate lg:mb-2"
      >
        {{ showNickname }}
      </h1>
      <WalletDisplay class="mx-auto lg:mb-2" />
      <a
        v-if="$store.getters['userInfo/getSkinReturnUrl']"
        :href="
          $store.getters['userInfo/getSkinReturnUrl'] +
          '/account/ricarica-conto.html'
        "
        target="_blank"
        class="ricarica-btn"
        >RICARICA IL TUO CONTO
      </a>
    </div>

    <nav id="nav-router-links">
      <a
        v-if="$store.getters['userInfo/getSkinReturnUrl']"
        id="first-nav-el"
        :href="$store.getters['userInfo/getSkinReturnUrl']"
        class="navigation-link-extended"
        >{{
          $store.getters["userInfo/getSkinName"]
            ? $store.getters["userInfo/getSkinName"]
            : "Casino"
        }}</a
      >
      <router-link
        class="navigation-link-extended"
        @click.native="menuClose()"
        :to="{ name: 'SlotsBar' }"
        >Mappa</router-link
      >
      <a
        v-if="
          $route.name === 'Bar' &&
          barName &&
          barName !== $store.getters['userInfo/getDefaultBarVisibleName']
        "
        class="navigation-link-extended router-link-exact-active"
        :class="{
          'flex gap-x-4 justify-center items-center': getBarFavicon(
            'current',
            barSlug
          ),
        }"
        @click.prevent="
          $router.push({ name: 'Bar', params: { barName: barSlug } });
          menuClose();
        "
      >
        <img
          v-if="currentBarFavicon"
          class="w-5 h-5"
          :src="currentBarFavicon"
          alt=""
        />
        {{ barName }}
      </a>
      <router-link
        v-if="!!$store.getters['userInfo/getDefaultBarVisibleName']"
        class="navigation-link-extended"
        :class="{
          'flex gap-x-4 justify-center items-center': getBarFavicon(
            'current',
            barSlug
          ),
        }"
        @click.native="menuClose()"
        :to="{
          name: 'Bar',
          params: {
            barName: $store.getters['userInfo/getDefaultBar'],
          },
        }"
      >
        <img
          v-if="defaultBarFavicon"
          class="w-10 h-10"
          :src="defaultBarFavicon"
          alt=""
        />

        {{ $store.getters["userInfo/getDefaultBarVisibleName"] }}</router-link
      >

      <button
        class="h-12"
        @click="installApp()"
        v-if="
          isIos &&
          !isStandalone &&
          ($store.getters['appSettings/getShowPWA'] == true ||
            $store.getters['appSettings/getShowPWA'] == undefined)
        "
      >
        <AppStoreBadgeB />
      </button>
      <button
        class="h-12"
        @click="installApp()"
        v-if="
          isAndroid &&
          deferredPrompt &&
          !isStandalone &&
          ($store.getters['appSettings/getShowPWA'] == true ||
            $store.getters['appSettings/getShowPWA'] == undefined)
        "
      >
        <PlayStoreBadgeB />
      </button>
    </nav>

    <a
      id="mail"
      :href="webmailUrl"
      target="_blank"
      v-if="isSlotMail"
      class="text-xs p-1 w-fit"
      :class="isSlotMail ? 'third-icon' : ''"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mx-auto"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
      <span class="text-xs">Slotmail</span>
    </a>

    <button
      id="qr-code"
      class="w-fit text-xs p-1"
      @click="
        showModal = true;
        menuClose();
      "
      :style="isPWAInIphone ? 'padding-bottom: 0.5rem' : ''"
    >
      <svg
        class="h-5 w-5 mx-auto"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
        />
      </svg>
      <span class="text-xs">QR Code</span>
    </button>

    <button
      id="logout"
      @click="logout"
      :style="isPWAInIphone ? 'padding-bottom: 0.5rem' : ''"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mr-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style="transform: scale(-1, 1)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        />
      </svg>
      <span class="text-xs">Logout</span>
    </button>
    <a
      v-if="isAgent"
      id="go-to-agent"
      :href="backofficeUrl"
      target="_blank"
      class="text-xs p-1 w-fit"
      :class="isSlotMail ? 'fourth-icon' : 'third-icon'"
      :style="isPWAInIphone ? 'padding-bottom: 0.5rem' : ''"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 mx-auto"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.3"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
      <span class="text-center">Promotor</span>
    </a>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo";
import { mapState, mapGetters } from "vuex";
import WalletDisplay from "@/components/WalletDisplay";
import AppStoreBadgeB from "./utils/AppStoreBadgeB.vue";
import PlayStoreBadgeB from "./utils/PlayStoreBadgeB.vue";
import QRCode from "./QRCode.vue";
import Modal from "./utils/Modal.vue";
import { getBarFavicon } from "@/api/barsRepository.js";

export default {
  name: "Menu",

  components: {
    UserInfo,
    WalletDisplay,
    AppStoreBadgeB,
    PlayStoreBadgeB,
    QRCode,
    Modal,
  },

  props: ["deferredPrompt"],

  data() {
    return {
      isOpen: false,
      isSlotMail: this.mailIsSlotmail(),
      webmailUrl: process.env.VUE_APP_WEBMAIL_URL,
      backofficeUrl: process.env.VUE_APP_BACKOFFICE_URL,
      showModal: false,
      showLogoutModal: false,
      isAndroid: /(android)/i.test(navigator.userAgent),
      barName: null,
      barSlug: null,
      inGame: false,
      currentBarFavicon: null,
      defaultBarFavicon: null,
    };
  },

  computed: {
    ...mapState("deviceInfo", ["isIos", "isStandalone"]),
    ...mapGetters("deviceInfo", ["isPWAInIphone"]),

    tokens() {
      return this.$store.getters["userInfo/getTokens"];
    },
    showFullscreenIcon() {
      return (
        !this.isIphone &&
        (navigator.userAgent.match(/android/i) || this.isIpad) &&
        !this.isStandalone
      );
    },

    isAgent() {
      return this.$store.state.userInfo.user
        ? this.$store.state.userInfo.user.isAgent
        : null;
    },

    showAvatar() {
      return this.$store.getters["userInfo/getAvatar"];
    },

    showNickname() {
      return this.$store.getters["userInfo/getNickname"];
    },
  },

  created() {
    this.$root.$on("closeMenu", this.menuClose);
    this.$root.$on("barNameUpdate", this.barNameUpdateHandler);
    this.$root.$on("openMenu", this.openMenuHandler);
    this.$root.$on("gameIframeOpened", this.gameIframeOpenedHandler);
  },

  mounted() {
    if (this.$store.getters["appSettings/getShowPWA"] == true && this.isIos)
      this.$root.$emit("showPWAModal");
  },

  methods: {
    async getBarFavicon(bar, barSlug) {
      if (this.barSlug) {
        getBarFavicon(barSlug)
          .then((res) => {
            console.log(typeof res.data);
            if (!!res.data) {
              if (bar === "default") this.defaultBarFavicon = res.data;
              if (bar === "current") this.currentBarFavicon = res.data;
            } else {
              return null;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      //   try {
      //     const res = await getBarFavicon(barSlug);
      //     console.log("res.data", res.data);
      //     // this.barFavicon = res.data
      //     return res.data;
      //   } catch (err) {}
    },

    gameIframeOpenedHandler(state) {
      this.inGame = state;
    },
    openMenuHandler() {
      this.isOpen = true;
    },
    barNameUpdateHandler({ barName, barSlug }) {
      this.barName = barName;
      this.barSlug = barSlug;
    },

    async installApp() {
      if (this.isIos) {
        this.$root.$emit("showPWAModal");
        this.menuClose();
      } else {
        this.deferredPrompt.prompt();
      }
    },
    async logoutCleanUp() {
      this.$store.dispatch("auth/cleanUpAuth");
      this.$store.dispatch("socket/destroySocket");
    },

    logout() {
      this.logoutCleanUp().then(() => {
        this.menuClose();
        this.chatClose();
        this.$router.push("/login");
      });
      // this.$store.dispatch("auth/setRefreshToken", null);
      // this.isCollapsed = true;
    },
    menuClose() {
      if (this.isOpen) {
        this.isOpen = false;
        this.$root.$emit("closeMenu");
      }
    },
    chatClose() {
      this.$root.$emit("closeChat");
    },

    imgClickHandler() {
      this.menuClose();
      this.chatClose();
      this.$root.$emit("showSettings", true);
    },

    mailIsSlotmail() {
      const email = this.$store.getters["userInfo/getEmail"];
      if (email) {
        return (
          email.toLowerCase().split("@").pop() ==
          process.env.VUE_APP_EMAIL_BASE_DOMAIN
        );
      }
    },

    replaceByDefault(e) {
      e.target.src = "/img/default_img.jpg";
    },
  },
};
</script>

<style lang="postcss" scoped>
.ricarica-btn {
  @apply w-fit mx-auto mt-5 xs:mt-3 lg:mt-5 mb-3 xs:mb-1 lg:mb-6 text-sm flex items-center justify-between border rounded-full border-secondary px-4 font-medium pt-1 pb-0.5;

  &:hover {
    background: linear-gradient(140deg, #f28928 23.81%, #ffbc10 73.15%);
    padding-left: 0.97rem;
    padding-right: 0.97rem;
    @apply text-gray-900 font-semibold;
  }
}
.menu-container {
  background: theme(colors.gray.900);
  top: 2.5rem;
  right: 0;
  width: 100%;
  height: calc(100% - 2.5rem);
  z-index: 2;
  @apply fixed flex flex-col justify-center pb-24 space-y-5 transform transition-all duration-300;

  &.is-closed {
    transform: translateY(calc(-100% - 2.5rem));
  }

  @screen xs {
    right: 0;
    @apply flex-row items-center pb-6 gap-x-10 pl-6;
  }

  @screen lg {
    width: 350px;
    padding-left: 0;
    @apply flex-col;
    &.is-closed {
      transform: translateX(100%);
    }
  }

  #nav-router-links {
    @apply flex flex-col space-y-2 min-w-0;
    @screen xs {
      /* padding-left: 8%; */
      margin-top: 0 !important;
      flex: 1;
      max-width: 500px;
      @apply mx-auto;

      #first-nav-el {
        margin-top: 0 !important;
      }
    }

    @screen lg {
      flex: 0;
      max-width: auto;
      @apply pb-24 pr-0 w-full mx-0;
    }
  }

  a.navigation-link-extended {
    background: theme(colors.gray.600);
    color: #ffffff;
    @apply py-2 px-4 uppercase text-lg text-center font-bold;
    /* purgecss start ignore */
    &.router-link-exact-active {
      background: linear-gradient(140deg, #2c6797 23.81%, #49b9d0 73.15%);
      /* color: theme(colors.gray.900); */
    }
    /* purgecss end ignore */

    @screen xs {
      @apply text-2xl space-y-5;
    }

    @screen lg {
      &:hover:not(.router-link-exact-active) {
        background: linear-gradient(140deg, #f28928 23.81%, #ffbc10 73.15%);
        /* color: theme(colors.gray.900); */
      }
    }
  }

  #logout {
    bottom: 0.5rem;
    @apply flex flex-col items-center text-xs w-fit p-1 fixed left-1.5;

    &:hover {
      @apply bg-secondary-gradient;
    }

    /* @screen lg {
      right: 3vh;
   } */
  }

  #qr-code {
    left: 4rem;
    bottom: 0.5rem;
    @apply fixed;
    /* @screen xs {
      left: 3vh;
      bottom: 3rem;
   } */
  }

  #mail {
    left: 7.9rem;
    bottom: 0.5rem;
    @apply fixed p-1 text-xs;
  }

  #go-to-agent {
    /* left: 12rem;
   bottom: 0.5rem; */
    @apply fixed p-1 text-xs;
  }

  @media (hover: hover) {
    /* .menu-btn:hover {
      @apply scale-105;

      span {
        box-shadow: 0 0 1px #ffffff;
      }
    } */
  }

  .third-icon {
    left: 7.9rem;
    bottom: 0.5rem;
    /* @apply fixed p-1 text-xs uppercase; */
  }

  .fourth-icon {
    left: 12rem;
    bottom: 0.5rem;
    /* @apply fixed p-1 text-xs uppercase; */
  }
}
</style>
