const events = {
  //server events
  NOTIFICATION: "notification",
  WIN: "win",
  EVENT_VALIDATION_ERROR: "event:validation:error",
  REWARD: "reward",

  //client events
  LOCATION_CHANGE: "location:change",
  ACK_REWARD: "ack:reward",
};

export { events };
