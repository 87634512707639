<template>
  <div
    class="message-balloon-wrapper"
    :class="{ sent: sent, 'same-user': sameSenderAsPreviousBalloon }"
  >
    <div class="img-container" v-if="lastSenderMessage && !sent">
      <img :src="img" @error="replaceByDefault" />
    </div>
    <div
      class="message-balloon-container unselectable"
      @click="handleClick"
      :style="reactions.length > 0 ? 'margin-bottom: -12px' : ''"
    >
      <p
        class="user-nickname"
        :class="{
          'ml-1': !sent,
          'ml-8': !lastSenderMessage && !sent,
        }"
        :style="{ color: color }"
        v-if="!sameSenderAsPreviousBalloon && !sent"
      >
        {{ nickname }}
      </p>
      <div
        class="message-balloon"
        :class="{
          'message admin': adminMessage,
          'message last-received': !sent && lastSenderMessage && !adminMessage,
          'message last-sent': sent && lastSenderMessage && !adminMessage,
          sent: sent,
          received: !sent,
          'ml-7': !sent && !lastSenderMessage,
        }"
        :style="{
          background: backgroundColor,
        }"
      >
        <p class="content">{{ content }}</p>
        <p class="time" :class="sent ? 'text-gray-400' : 'text-gray-600'">
          {{ time }}
        </p>
      </div>
      <div
        v-if="reactions.length > 0"
        class="reactions-container"
        @click="reactionClick"
        :class="{
          sent: sent,
          received: !sent && !adminMessage,
          admin: adminMessage,
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.61 133.46">
          <defs>
            <linearGradient id="gradient-blue">
              <stop offset="25%" stop-color="rgb(5, 6, 41)" />
              <stop offset="75%" stop-color="rgb(21, 60, 115)" />
            </linearGradient>
          </defs>
          <path
            class="white"
            d="M142,76a13.51,13.51,0,0,0,5-10.88,17,17,0,0,0-.39-3.76,12.57,12.57,0,0,0-12.24-9.72H101.94l3-17.31a19.77,19.77,0,0,0-.26-8.07,64.31,64.31,0,0,0-7.54-18A7.82,7.82,0,0,0,94.2,5.5a16.28,16.28,0,0,0-7.69-2A15.79,15.79,0,0,0,77,6.86a9.4,9.4,0,0,0-3.73,7.47V31.9a.61.61,0,0,1,0,.19c-3,10.31-17.37,27.4-19,29.31l-.11.11L52,63.16a7.85,7.85,0,0,0-7-4.35H11.37A7.87,7.87,0,0,0,3.5,66.68v55.38a7.87,7.87,0,0,0,7.87,7.87H44.93a7.88,7.88,0,0,0,7.48-5.42l6.41,3.2a7.77,7.77,0,0,0,3.47.82l61.82-.06c6.59,0,13.93-5.32,13.93-13a17,17,0,0,0-1.4-6.93,13.91,13.91,0,0,0,5-10.16A13.13,13.13,0,0,0,140.06,92a13.35,13.35,0,0,0,4-9.16A12.09,12.09,0,0,0,142,76Z"
            transform="translate(0 0.02)"
          />
          <path
            class="gradient-blue"
            d="M146.48,76.7a17.26,17.26,0,0,0,4.13-11.58,20.56,20.56,0,0,0-.46-4.49A16.09,16.09,0,0,0,134.5,48.16H106.21L108.49,35a23.18,23.18,0,0,0-.31-9.49,67.78,67.78,0,0,0-8-19,11.24,11.24,0,0,0-4.27-4A19.77,19.77,0,0,0,86.58,0l0,0A19.3,19.3,0,0,0,74.93,4.05a12.92,12.92,0,0,0-5.12,10.26V31.48C67.39,39,58.13,51.28,52.43,58.15a11.31,11.31,0,0,0-7.49-2.84H11.37A11.38,11.38,0,0,0,0,66.68v55.39a11.38,11.38,0,0,0,11.37,11.37H44.94a11.37,11.37,0,0,0,8.92-4.33l3.43,1.71a11.25,11.25,0,0,0,5,1.19h62c9.29,0,17.43-7.73,17.43-16.54a21,21,0,0,0-.86-6,16.41,16.41,0,0,0,3.43-16.79,16.29,16.29,0,0,0,3.43-9.76A15.92,15.92,0,0,0,146.48,76.7ZM44.94,126.44H11.37A4.37,4.37,0,0,1,7,122.07V66.68a4.37,4.37,0,0,1,4.37-4.37H44.94a4.31,4.31,0,0,1,1.86.43,4.35,4.35,0,0,1,2.51,3.94v55.39a4.36,4.36,0,0,1-2,3.66A4.27,4.27,0,0,1,44.94,126.44Zm93.27-52a1.29,1.29,0,0,0-.55,1.74,1.57,1.57,0,0,0,.24.32,8.91,8.91,0,0,1,2.77,6.46c0,4-3,6.91-4.52,8.08a.85.85,0,0,0-.17,1.19l.05.06a9.25,9.25,0,0,1,2.2,6.2c0,3.82-2.82,7-4.94,8.44a1.37,1.37,0,0,0-.38,1.86,13.06,13.06,0,0,1,1.77,6.79c0,5.32-5.32,9.54-10.43,9.54h-62a4.18,4.18,0,0,1-1.9-.45l-4.07-2c0-.17,0-.34,0-.52V66.68a11.45,11.45,0,0,0-.23-2.28l.16-.12a4.1,4.1,0,0,0,.64-.61C59.1,61.06,73.4,44,76.62,33.08a4.25,4.25,0,0,0,.16-1.18V14.33a5.85,5.85,0,0,1,2.37-4.7A12.43,12.43,0,0,1,86.6,7V7a12.88,12.88,0,0,1,6,1.61,4.3,4.3,0,0,1,1.61,1.5,60.75,60.75,0,0,1,7.11,17,16.56,16.56,0,0,1,.22,6.65L98,54.3a.73.73,0,0,0,.59.85h35.84a9,9,0,0,1,8.82,7,14.41,14.41,0,0,1,.31,3C143.56,70.72,140.38,73.29,138.21,74.39Z"
            transform="translate(0 0.02)"
          />
        </svg>
        {{ reactions.length }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBalloon",

  props: {
    
    sent: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "#FFFFFF",
    },

    content: {
      type: String,
      required: true,
    },

    nickname: {
      type: String,
      required: true,
    },

    img: {
      type: String,
      default: "/img/default_img.jpg",
    },

    adminMessage: {
      type: Boolean,
      default: false,
    },

    sameSenderAsPreviousBalloon: {
      type: Boolean,
      default: false,
    },

    lastSenderMessage: {
      type: Boolean,
      default: false,
    },

    time: {
      type: String,
      required: true,
    },

    reactions: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      clicks: 0,
      timer: null,
    };
  },

  computed: {
    backgroundColor() {
      if (this.sent) {
        /* .sent */
        return [
          "rgb(5, 6, 41)",
          "-moz-linear-gradient( 172deg, rgba(5, 6, 41, 1) 4%, rgba(21, 60, 115, 1) 75%)",
          "-webkit-linear-gradient(172deg, rgba(5, 6, 41, 1) 4%, rgba(21, 60, 115, 1) 75%)",
          "linear-gradient(172deg, rgba(5, 6, 41, 1) 4%, rgba(21, 60, 115, 1) 75%)",
        ];
      } else if (this.adminMessage) {
        return "rgb(230, 145, 34)";
      } else if (!this.sent) {
        /* received */
        return [
          "rgb(179, 189, 225)",
          "-moz-linear-gradient(172deg, rgba(179, 189, 225, 1) 25%, rgba(228, 228, 231, 1) 75%)",
          "-webkit-linear-gradient(172deg, rgba(179, 189, 225, 1) 25%, rgba(228, 228, 231, 1) 75%)",
          "linear-gradient(172deg, rgba(179, 189, 225, 1) 25%, rgba(228, 228, 231, 1) 75%)",
        ];
      }
    },
  },

  methods: {
    reactionClick() {
      const hasMyReaction = this.reactions.some(
        (reaction) => reaction.by == this.$store.getters["userInfo/getNickname"]
      );
      if (hasMyReaction) {
        this.$emit("double-click-detected");
      }
    },
    replaceByDefault(e) {
      e.target.src = "/img/default_img.jpg";
    },

    handleClick() {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
        }, 250);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
        // Perform double click action
        this.$emit("double-click-detected");
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.gradient-blue {
  background: rgb(5, 6, 41);
  background: -moz-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.message-balloon-wrapper {
  @apply flex gap-x-2;
  &.sent {
    @apply flex-row-reverse;
  }

  &.same-user {
    margin-top: 0.35rem !important;
  }

  .img-container {
    @apply h-5 w-5 overflow-hidden rounded-full bg-gray-800 shadow-1 mt-auto mb-0.5;

    img {
      @apply rounded-full object-cover mx-auto h-full w-full;
    }
  }

  .message-balloon-container {
    /* min-width: fit-content; */
    width: fit-content;
    max-width: 75%;
    @apply space-y-1;

    .reactions-container {
      z-index: 1;
      font-size: 11px;
      bottom: 11px;
      filter: drop-shadow(0 0 2px rgba(23, 23, 23, 0.3));
      padding-top: 0.05rem;
      @apply px-1.5 w-fit h-fit flex gap-x-1 items-center rounded-full relative border-t border-r border-l border-gray-800 font-medium;

      &.sent {
        background-color: rgb(21, 60, 115);
        @apply ml-1;
      }

      &.received {
        background-color: #e4e4e7;
        @apply text-gray-900 ml-auto mr-1;
      }

      &.admin {
        background-color: rgb(230, 145, 34);
        @apply text-gray-900 ml-auto mr-1;
      }

      svg {
        width: 0.92rem;
        height: 0.92rem;
        margin-top: -0.5px;
        .gradient-blue {
          fill: url(#gradient-blue) #fff;
        }
        .white {
          fill: rgb(231, 173, 17);
        }
      }
    }

    .user-nickname {
      @apply text-xs w-fit pl-1;
      &.sent {
        @apply ml-auto mr-1;
      }
    }

    .message-balloon {
      /*add min e max width*/
      min-width: fit-content;
      border-radius: 1.25rem;
      box-shadow: 3px 3px 6px rgb(23, 23, 23);
      @apply h-fit relative pb-1 pt-1 px-4 relative flex justify-end items-end min-w-0;

      .content {
        @apply flex-1 min-w-0 break-words;
      }

      &.sent {
        filter: saturate(85%);
        @apply ml-auto;
      }

      &.received {
        @apply bg-gray-200;
        & * {
          @apply text-black;
        }
      }

      .time {
        font-size: 0.6rem;
        @apply h-fit pl-1 -mr-1.5 mb-0.5 text-right;
      }

      &.message {
        border-radius: 20px;
        margin-bottom: 7px;
      }

      &.last-received:before {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 1px;
        left: 2px;
        height: 11px;
        width: 14px;
        background: rgb(179, 189, 225);
        background: -moz-linear-gradient(
          38deg,
          rgba(228, 228, 231, 1) 40%,
          transparent 62%,
          transparent 100%
        );
        background: -webkit-linear-gradient(
          38deg,
          rgba(228, 228, 231, 1) 40%,
          transparent 62%,
          transparent 100%
        );
        background: linear-gradient(
          38deg,
          rgba(228, 228, 231, 1) 40%,
          transparent 62%,
          transparent 100%
        );
        border-bottom-right-radius: 15px;
      }

      &.last-sent:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 1px;
        right: 2px;
        height: 9px;
        width: 14px;
        background: rgb(21, 60, 115);
        background-attachment: fixed;
        border-bottom-left-radius: 15px;
      }

      &.admin:before {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 1px;
        left: 2px;
        height: 11px;
        width: 14px;
        background: rgb(230, 145, 34);
        background-attachment: fixed;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
</style>
