var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "welcome",
      staticClass: "welcome",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("closeIntroVideo")
        },
      },
    },
    [
      _c("img", {
        ref: "welcomeAnimation",
        staticClass: "welcome__anim",
        attrs: {
          src: "/img/VR_LogoAnim.webp",
          alt: "Virtual Rooms logo animation",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }