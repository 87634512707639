<template>
  <div class="chat__typing-area" ref="ta">
    <div class="shadow h-10 -top-10 left-0"></div>
    <div v-if="showLinkNotification" class="notification">
      <button>La condivisione tramite link non è consentita</button>
    </div>
    <div class="notification" v-if="moreMessages">
      <button @click.prevent="seeNewMessagesClick">
        Visualizza i nuovi messaggi
      </button>
    </div>
    <div
      class="text-area--container"
      :class="isTextareaFocused ? 'border-white' : 'border-transparent'"
    >
      <textarea
        ref="chat-input"
        placeholder="Invia il tuo messagio..."
        @keyup.enter="sendMessage"
        @focus="inputFocusHandler"
        @blur="isTextareaFocused = false"
        @input="handleInput"
      >
      </textarea>
      <p>
        <span :class="{ 'text-red font-medium': invalidMsgLength }">{{
          msgLength
        }}</span>
        /{{ maxMsgLength }}
      </p>
    </div>

    <RoundedBtn
      @btn-clicked="sendMessage"
      :class="{ disabled: msgLength === 0 || invalidMsgLength }"
      :size="10"
    >
      <template v-slot:content>
        <img src="/img/icons/SEND_ICON.png" class="pr-0.5" />
      </template>
    </RoundedBtn>
  </div>
</template>

<script>
import RoundedBtn from "@/chat/components/buttons/RoundedBtn.vue";

export default {
  name: "TypingArea",

  components: { RoundedBtn },

  props: {
    moreMessages: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      text: "",
      room: this.$route.params.barName,
      showLinkNotification: false,
      isTextareaFocused: false,
      invalidMsgLength: false,
      maxMsgLength:
        parseInt(process.env.VUE_APP_CHAT_MESSAGE_LENGTH_LIMIT) || 120,
    };
  },

  created() {
    // this.maxMsgLength =
    //   parseInt(process.env.VUE_APP_CHAT_MESSAGE_LENGTH_LIMIT) || 120;
  },

  mounted() {
    this.$emit('typingAreaHeight', this.$refs["ta"].offsetHeight)
  },

  computed: {
    msgLength() {
      if (this.text.length > this.maxMsgLength) {
        this.invalidMsgLength = true;
      } else {
        this.invalidMsgLength = false;
      }
      return this.text.length;
    },
  },

  methods: {
    inputFocusHandler() {
      this.isTextareaFocused = true;
      this.$root.$emit("keyboardIsOpen");
    },
    handleInput(ev) {
      this.text = ev.target.value;
    },

    seeNewMessagesClick() {
      this.$emit("new-messages-click");
    },

    sendMessage() {
      this.text = this.text.replace(/[\r\n]/gm, "");
      if (this.text.length > 0) {
        if (!this.stringIncludesLink(this.text)) {
          const data = { content: this.text, room: this.room };
          this.$emit("send-message", data);
        } else {
          this.showLinkNotification = true;
          setTimeout(() => {
            this.showLinkNotification = false;
          }, 5000);
        }
        this.text = "";
        this.$refs["chat-input"].value = "";
      }
    },

    stringIncludesLink(string) {
      const regex = /(http(s)?:\/\/[^\s]+)/gi;
      if (string.match(regex)) {
        console.log("Link detected!");
        return true;
      } else {
        console.log("No link detected.");
        return false;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.chat__typing-area {
  @apply bg-gray-700 min-h-fit px-2 py-1.5 flex items-center gap-x-2 relative;
  .text-area--container {
    @apply relative flex-1 h-fit flex items-center bg-gray-800 rounded-xl pb-5 pt-2 border;

    textarea {
      &:focus {
        border: none;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      @apply px-3 bg-transparent overflow-y-auto w-full;
    }

    p {
      @apply absolute right-2 bottom-1 text-xs text-gray-400;
    }
  }

  .notification {
    @apply absolute -top-12 left-0 w-full flex justify-center opacity-90;

    button {
      @apply text-sm rounded-full bg-gray-500 p-2 px-4 w-fit;
    }
  }

  /* .players-btn {
    @apply flex items-end pl-2 py-1 gap-x-1;
  } */
}
</style>
