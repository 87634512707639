import httpClient from "@/helpers/httpClient";

const basePath = "tokens";

export default {
  getCredit,
};

export function getCredit(id) {
  return httpClient().get(`${basePath}/${id}/balance`);
}

export function depositAmount(userId, amount) {
  return httpClient().post(`${basePath}/${userId}/deposit`, { amount });
}

export function withdrawAmount(userId, amount) {
  return httpClient().post(`${basePath}/${userId}/withdrawal`, { amount });
}

export function getAllTokensMovements(userId) {
  return httpClient().get(`${basePath}/${userId}/movements`);
}
