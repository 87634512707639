var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "appContainer",
      style: _vm.keyboardHeight ? "overflow: hidden" : null,
      attrs: { id: "app" },
    },
    [
      _vm.showIntroVideo
        ? _c("WelcomePage", {
            attrs: { displayIntroVideo: _vm.displayIntroVideo },
            on: {
              closeIntroVideo: function ($event) {
                _vm.showIntroVideo = false
              },
            },
          })
        : _vm._e(),
      _c(
        "fullscreen",
        {
          ref: "fullscreen",
          staticClass:
            "h-full w-screen overflow-hidden fixed flex flex-col lg:flex-row",
          attrs: { id: "fullscreen" },
          on: { change: _vm.callFullscreen },
        },
        [
          _c("TopBar", {
            ref: "topBar",
            attrs: {
              isInFullscreen: _vm.isInFullscreen,
              isInGame: _vm.isInGame,
              isSearchPopUpOpen: _vm.isSearchPopUpOpen,
              keyboardHeight: _vm.keyboardHeight,
            },
          }),
          _vm.$store.getters["auth/getIsAuthenticated"]
            ? _c("Menu", {
                attrs: { deferredPrompt: _vm.deferredPrompt },
                on: { showSettings: _vm.showSettingsHandler },
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "main-container__fullscreen-mob-por main-container__fullscreen-mob-land main-container__fullscreen-tab":
                  _vm.isInFullscreen,
              },
              attrs: { id: "main-container" },
            },
            [
              _c("router-view", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showSettings,
                    expression: "!showSettings",
                  },
                ],
                attrs: {
                  hideFullscreenBtn:
                    !_vm.showFullscreenBtn || _vm.isSearchPopUpOpen,
                  isInFullscreen: _vm.isInFullscreen,
                },
                on: {
                  isInGameUpdate: _vm.isInGameUpdateHandler,
                  searchPopUpVisibility: _vm.searchPopUpVisibilityHandler,
                  fullscreenBtnVisibility: _vm.fullscreenBtnVisibilityHandler,
                },
              }),
              _vm.showSettings ? _c("Settings") : _vm._e(),
            ],
            1
          ),
          _vm.isIos &&
          _vm.showPWAModal &&
          !_vm.isStandalone &&
          _vm.isAuthenticated
            ? _c("PWAiOs", { on: { closePWAModal: _vm.closePWAModalHandler } })
            : _vm._e(),
          _vm.isAndroid &&
          _vm.deferredPrompt &&
          !_vm.isStandalone &&
          _vm.showPWAModal &&
          _vm.isAuthenticated
            ? _c("PWAAndroid", {
                attrs: { deferredPrompt: _vm.deferredPrompt },
                on: { closePWAModal: _vm.closePWAModalHandler },
              })
            : _vm._e(),
          _vm.rewardEventData
            ? _c("DailyRewardModal", {
                attrs: { data: _vm.rewardEventData, hideX: true },
                on: { close: _vm.closeDailyReward },
              })
            : _vm._e(),
          _c("Toasts"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }