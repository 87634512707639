var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "top-bar" } }, [
    _c(
      "div",
      {
        staticClass: "flex flex-1 min-w-0",
        class: {
          "w-full justify-center":
            !_vm.$store.getters["auth/getIsAuthenticated"],
        },
        on: { click: _vm.closeMenu },
      },
      [
        _c(
          "button",
          {
            staticClass: "px-4 py-3 min-w-fit",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.pushToHomeRoute.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              class: _vm.chooseVrLogo[0],
              attrs: {
                src: _vm.chooseVrLogo[1],
                alt: "Virtual Rooms Logo Icon",
              },
            }),
          ]
        ),
        !!_vm.commitHash
          ? _c("p", { staticClass: "text-xs text-gray-500" }, [
              _vm._v(" COMMIT: " + _vm._s(_vm.commitHash) + " "),
            ])
          : _vm._e(),
        _vm.barName && !_vm.minWidth834
          ? _c(
              "a",
              {
                staticClass: "barName",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.backToRoomClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.barName) + " ")]
            )
          : _vm._e(),
      ]
    ),
    _vm.$store.getters["auth/getIsAuthenticated"]
      ? _c(
          "div",
          {
            staticClass: "flex gap-x-1 h-full",
            staticStyle: { width: "200px" },
          },
          [
            _c("WalletDisplay", {
              staticClass: "border-r-2 border-gray-700 pr-4",
              attrs: { hideTokens: true, hideTitles: true },
            }),
            _c("Chat", {
              attrs: {
                isInFullscreen: _vm.isInFullscreen,
                isInGame: _vm.isInGame,
                isSearchPopUpOpen: _vm.isSearchPopUpOpen,
                keyboardHeight: _vm.keyboardHeight,
              },
            }),
            _vm.$store.getters["auth/getIsAuthenticated"]
              ? _c(
                  "button",
                  {
                    staticClass: "menu-btn",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.menuInteraction.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menu-btn--span-container" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }