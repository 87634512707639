// initial state
const state = () => ({
  isStandalone: false,
  isIphone: false,
  isIpad: false,
  isIos: false,
  isAndroid: false,
  isStandalone: false,
});

// getters
const getters = {
  isPWAInIphone(state) {
    return state.isIphone && state.isStandalone;
  },
};

// actions
const actions = {
  async setDeviceInfo({ commit, dispatch }) {
    try {
      await Promise.all([
        dispatch("setIsStandalone"),
        dispatch("setIsIphone"),
        dispatch("setIsIpad"),
        dispatch("setIsAndroid"),
      ]);
      dispatch("setIsIos");
    } catch (err) {
      console.error(err);
    }
  },

  async setIsStandalone({ commit }) {
    if (
      ("standalone" in window.navigator && window.navigator.standalone) ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      commit("SET_IS_STANDALONE");
    }
  },

  async setIsIphone({ commit }) {
    if (window.navigator.userAgent.match(/iphone|ipod/i)) {
      commit("SET_IS_IPHONE");
    }
  },
  async setIsIpad({ commit }) {
    const ua = window.navigator.userAgent;
    // In iOS 13 Apple removed "iPad" from the iPad user-agent... So we need to check for touch points as well
    if (
      (ua.toLowerCase().indexOf("macintosh") > -1 &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2) ||
      ua.match(/ipad/i)
    ) {
      commit("SET_IS_IPAD");
    }
  },
  setIsIos({ commit }) {
    commit("SET_IS_IOS");
  },
  setIsAndroid({ commit }) {
    if (/(android)/i.test(navigator.userAgent)) {
      commit("SET_IS_ANDROID");
    }
  },
};

// mutations
const mutations = {
  SET_IS_STANDALONE(state) {
    state.isStandalone = true;
  },

  SET_IS_IPHONE(state) {
    state.isIphone = true;
  },
  SET_IS_IPAD(state) {
    state.isIpad = true;
  },
  SET_IS_IOS(state) {
    console.log(state.isIphone);

    if (state.isIphone || state.isIpad) {
      state.isIos = true;
    }
  },
  SET_IS_ANDROID(state) {
    state.isAndroid = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
