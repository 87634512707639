<template>
  <div class="pwaIos bubble">
    <div class="pwaIos__overlay" @click="$emit('closePWAModal')"></div>
    <button
      @click="$emit('closePWAModal')"
      class="text-xl absolute right-1 -top-2 p-2"
      style="filter: drop-shadow(0 1px 3px rgb(0 0 0 / 1))"
    >
      &times;
    </button>
    <div class="w-full" v-show="!showLong">
      <img
        src="/pwa/images/icons/icon-144x144.png"
        alt="Virtual Rooms Logo"
        class="w-16 rounded-xl mx-auto"
      />
      <p class="w-full text-center text-lg mt-1 mb-3">Virtual Rooms</p>
    </div>
    <p class="text-sm">Per aggiungere Virtual Rooms alla schermata iniziale:</p>
    <div class="pwaIos__short" v-show="!showLong">
      <ul>
        <li class="text-sm pl-2">
          - Clicca
          <img
            src="../../public/img/share-icon-ios.png"
            class="h-6 w-auto inline"
            style="filter: invert(100%); -webkit-filter: invert(100%)"
            alt="iOS share icon"
          />
        </li>
        <li class="text-sm pl-2">
          - Clicca <b>Aggiungi alla schermata </b>
          <span class="inline-block">
            <b>Home</b>
            <img
              src="../../public/img/add-icon-ios.png"
              class="h-6 w-auto inline ml-1"
              style="filter: invert(100%); -webkit-filter: invert(100%)"
              alt="iOS add icon"
            />
          </span>
        </li>
        <li class="pl-2 text-sm">- Conferma su <b>Aggiungi</b></li>
      </ul>
    </div>

    <div class="pwaIos__long" v-show="showLong">
      <video
        ref="pwaDemo"
        src="/img/shortcut-instructions.mp4"
        loop
        muted
        playsinline
        autoplay
      ></video>
    </div>

    <div class="flex justify-between mt-4">
      <button
        class="px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium border-b border-white text-white"
        @click="showLong = !showLong"
      >
        {{ showLong ? "Chiudere" : "Piú" }} dettagli
      </button>
      <button
        class="px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium border-b border-white text-white"
        @click="dontShowPWAModalAgain()"
      >
        Non vedere più
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PWAiOs",

  data() {
    return {
      showLong: false,
      stopShowingPWAModal: false,
    };
  },

  watch: {
    showLong(newValue) {
      if (newValue == true) {
        this.$refs.pwaDemo.currentTime = 0;
      }
    },
  },

  methods: {
    dontShowPWAModalAgain() {
      this.$emit("closePWAModal");
      this.$store.dispatch("appSettings/updateShowPwa", false);
    },
  },
};
</script>

<style lang="postcss" scoped>
.pwaIos {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  max-height: 83.5%;
  margin: 2.5%;
  width: 95%;
}

.pwaIos__overlay {
  top: 2.5rem;
  height: calc(100% - 2.5rem);
  z-index: -1;
  @apply bg-gray-900 bg-opacity-50 w-full fixed left-0;
}

.pwaIos__short {
  height: fit-content;
}

.pwaIos__long {
  max-height: 61.5vh;
  video {
    max-height: 61.5vh;
    @apply mx-auto;
  }
}

.bubble {
  background-color: theme(colors.gray.900);
  border-radius: 5px;
  box-shadow: 0 0 6px #111827;
  display: inline-block;
  padding: 1.5rem 1rem;
  /* position: relative; */
  vertical-align: top;
  font-size: 0.9rem;
  max-height: 88.5%;
}

.bubble::before {
  background-color: theme(colors.gray.900);
  content: "\00a0";
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  bottom: -7.5px;
  left: calc(50% - 16px);
  transform: rotate(47deg) skew(5deg);
  -moz-transform: rotate(47deg) skew(5deg);
  -ms-transform: rotate(47deg) skew(5deg);
  -o-transform: rotate(47deg) skew(5deg);
  -webkit-transform: rotate(47deg) skew(5deg);
  box-shadow: 2px 2px 2px 0 #111827;
}
</style>
