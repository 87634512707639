<script>
export default {
  name: "minWidthMixin475",

  data() {
    return {
      minWidthXS: window.innerWidth >= 475,
    };
  },

  methods: {
    startEventMWXS() {
      window.addEventListener("resize", this.defineMinWidthXS);
    },

    defineMinWidthXS() {
      this.minWidthXS = window.innerWidth >= 475;
    },
  },

  created() {
    this.startEventMWXS();
  },

  destroyed() {
    window.removeEventListener("resize", this.defineMinWidthXS);
  },
};
</script>
<style lang="postcss"></style>
