import Cookies from "js-cookie";
import userRepository from "../../api/userRepository";
import {
  login,
  loginFromSkinWithCredentials,
  loginFromSkin,
  signupFromSkin,
} from "../../api/authRepository";
import { getSettings } from "../../api/settingsRepository";
import { getCredit as getBalance } from "../../api/creditRepository";
import { getCredit as getTokens } from "../../api/tokensRepository";
import { LoginError, AuthError } from "../../common/errorTypes.js";
import { ERROR_CODE } from "../../helpers/errorCodes.js";

const state = () => ({
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  userId: null,
  externalUserId: null,
  numContract: null,
  deviceId: null,
  sessionId: null,
});

// getters
const getters = {
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getDeviceId: (state) => state.deviceId,
  getIsAuthenticated: (state) => state.isAuthenticated,
  getUserId: (state) => state.userId,
  getNumContract: (state) => state.numContract,
};

// actions
const actions = {
  async loginUser(
    { dispatch },
    {
      isSkinIntegrated = false,
      email = null,
      password = null,
      token = null,
      contractNumber = null,
      sessionId = null,
      skinId = null,
      skin = null,
      polling = false,
    }
  ) {
    console.log("1 - loginUser");
    const data = {
      email,
      password,
      sessionId,
      skinId,
      skin,
    };
    try {
      const userInfo = await dispatch("authSkin", data);

      // if (/* FROM LOGIN */ email && password) {
      //   // const res = await loginFromSkinWithCredentials({
      //   //   loginField: email,
      //   //   password,
      //   //   skinId,
      //   // });
      //   //
      //   // if (res.status === 202) {
      //   //   await dispatch("authSkinPolling", {
      //   //     loginField: email,
      //   //     password,
      //   //     skinId,
      //   //   });
      //   // }
      // } /* FROM AUTH OR APP */ else if (sessionId) {
      // } /* FALLBACK */ else {
      // }

      // if (res.status === 202) {
      //   console.log("fui eu 2");

      //   console.log("GOT 202 on login User, about to go Polling");
      //   await dispatch("authSkinPolling", {
      //     loginField: email,
      //     password,
      //     skinId,
      //   });
      // }

      // const userInfo = await dispatch("authSkin", {
      //   isSkinIntegrated,
      //   email,
      //   password,
      //   token,
      //   contractNumber,
      //   skinId,
      //   polling,
      // });

      if (userInfo && userInfo.data) {
        await dispatch("setUpAuth", userInfo.data);
        await dispatch("getUserInfo", userInfo.data.userId);
      }
    } catch (err) {
      console.log("catchy catchy");
      console.log(err);
      throw err;

      // console.log("CATCH");
      // console.log("err.response.status", err.response.status);
      // console.error(err);
      // if (
      //   err.response &&
      //   (err.response.status == 401 || err.response.status == 400)
      // ) {
      //   throw new LoginError(
      //     "Wrong credentials",
      //     ERROR_CODE["LOGIN_ERROR_WRONG_CREDENTIALS"]
      //   );
      // } else if (err.response && err.response.status == 418) {
      //   throw new LoginError(
      //     "Missing skin",
      //     ERROR_CODE["LOGIN_ERROR_MISSING_SKIN"]
      //   );
      // } else {
      //   throw new LoginError("Something went wrong");
      // }
    }
  },

  async authSkin({ dispatch, commit }, data) {
    try {
      let res;
      res = await loginFromSkinWithCredentials(data);
      if (res.status === 202) {
        const resPolling = await dispatch("authSkinPolling", data);

        if (resPolling.status === 201) {
          return resPolling;
        }
      } else {
        return res;
      }
    } catch (err) {
      console.log("ERRRRRRRRR 2", err.response.status);
      if (err.response && err.response.status)
        switch (err.response.status) {
          case 418:
            throw new LoginError(
              "Missing skin",
              ERROR_CODE["LOGIN_ERROR_MISSING_SKIN"]
            );
          case 401 || 400:
            throw new LoginError(
              "Wrong credentials",
              ERROR_CODE["LOGIN_ERROR_WRONG_CREDENTIALS"]
            );
          case 404 || 403:
            this._vm.$router.push({ name: "404" });
          case 302:
            if (err.response.data.redirectUrl) {
              window.location.href = err.response.data.redirectUrl;
            }
          default:
            throw new LoginError("Something went wrong");
        }
    }

    // let res;
    // if (/*LOGIN*/ email && password) {
    //   try {
    //     if (isSkinIntegrated) {
    //       res = await loginFromSkinWithCredentials({
    //         loginField: email,
    //         password,
    //         skinId,
    //       });
    //       if (res.status === 202) {
    //         await dispatch("authSkinPolling", {
    //           loginField: email,
    //           password,
    //           skinId,
    //         });
    //       }
    //     } else {
    //       res = await login({ loginField: email, password });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     if (
    //       err.response &&
    //       (err.response.status == 401 || err.response.status == 400)
    //     ) {
    //       throw new LoginError(
    //         "Wrong credentials",
    //         ERROR_CODE["LOGIN_INVALID_WRONG_CREDENTIALS"]
    //       );
    //     } else if (err.response && err.response.status == 418) {
    //       throw new LoginError(
    //         "Missing skin",
    //         ERROR_CODE["LOGIN_ERROR_MISSING_SKIN"]
    //       );
    //     } else {
    //       throw new LoginError("Something went wrong");
    //     }
    //   }
    // } else if (/* AUTH */ token /*&& contractNumber && skinId*/) {
    //   try {
    //     dispatch("cleanUpAuth");
    //     dispatch("socket/destroySocket", null, { root: true });
    //     res = await loginFromSkin({ token, polling: false });
    //     if (res.status === 202) {
    //       await dispatch("authSkinPolling", {
    //         token,
    //       });
    //     }
    //   } catch (err) {
    //     // Handle with auth error
    //     if (/* SIGNUP AND LOGIN */ err.response && err.response.status == 404) {
    //       try {
    //         //TODO
    //         await signupFromSkin({ token });
    //         res = await dispatch("authSkinPolling", {
    //           token,
    //         });
    //         // if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
    //         //   this.$store.dispatch("socket/init");
    //         // }
    //       } catch (err) {
    //         /* REDIRECT */
    //         if (
    //           err.response &&
    //           err.response.status == 302 &&
    //           !!err.response.data.redirectUrl
    //         ) {
    //           window.location.href = err.response.data.redirectUrl;
    //         } else if (err.response && err.response.status == 404) {
    //           /* ROUTER */
    //           this._vm.$router.push({ name: "404" });
    //         } else if (err.response && err.response.status == 400) {
    //           /* ROUTER */
    //           throw new LoginError("Something went wrong");
    //         }
    //         console.error(err);
    //       }
    //     } else if (
    //       /* ROUTER */ err.response &&
    //       (err.response.status == 400 || err.response.status == 403)
    //     ) {
    //       this._vm.$router.push({ name: "404" });
    //     } else if (
    //       /* REDIRECT */ err.response &&
    //       err.response.status == 302 &&
    //       !!err.response.data.redirectUrl
    //     ) {
    //       window.location.href = err.response.data.redirectUrl;
    //     }
    //     throw err;
    //   }
    // } else {
    //   if (userConfigData) {
    //     res.data = userConfigData;
    //   } else {
    //     dispatch("cleanUpAuth");
    //     /* TODO is this valid? */
    //     dispatch("socket/destroySocket", null, { root: true });
    //   }
    // }
    // if (res.status === 202) {
    //   await dispatch("authSkinPolling", {
    //     loginField: email,
    //     password,
    //     skinId,
    //   });
    // } else {
    //   dispatch("setUpAuth", { ...res.data, isAuthenticated: true });
    // }
    // return res;
  },

  async authSkinPolling({ dispatch }, data) {
    console.log("Entering AuthSkinPolling");

    this._vm.$emit("polling");

    return new Promise((resolve, reject) => {
      loginFromSkinWithCredentials({
        ...data,
        polling: true,
      })
        .then((res) => {
          console.log("resolving promise");
          resolve(res);
        })

        .catch(async (err) => {
          console.log("catching promise");

          if (err.response.status === 503) {
            console.log("despatching ele proprio");
            let timeout = 1000;
            setTimeout(async () => {
              dispatch("authSkinPolling", data).then((res) => {
                resolve(res);
              });
            }, timeout);
          } else {
            reject(err);
          }
        });
    });

    // if (res.status === 202) {
    //   dispatch("authSkinPolling", { email, password, skinId });
    // }

    // try {
    //   if (token) {
    //     res = await loginFromSkin(token, true);
    //   } else if (email && password && skinId) {
    //     res = await loginFromSkinWithCredentials({
    //       loginField: email,
    //       password,
    //       skinId,
    //       polling,
    //     });
    //   }
    //   return res;
    // } catch (err) {
    //   console.error("err", err);
    //   if (err.response.status == 403) {
    //     this._vm.$router.push({ name: "404" });
    //   }

    //   // if (err.response.status == 503) {
    //   //   let timeout = 1000;
    //   //   setTimeout(async () => {
    //   //     if (token) {
    //   //       await dispatch("authSkinPolling", {
    //   //         token,
    //   //       });
    //   //     } else if (loginField && password && skinId) {
    //   //       //TODO here help
    //   //       await dispatch("authSkinPolling", {
    //   //         loginField: email,
    //   //         password,
    //   //         skinId,
    //   //       });
    //   //     }
    //   //   }, timeout);
    //   // }

    //   if (
    //     err.response &&
    //     (err.response.status == 401 || err.response.status == 400)
    //   ) {
    //     throw new LoginError(
    //       "Wrong credentials",
    //       ERROR_CODE["LOGIN_INVALID_WRONG_CREDENTIALS"]
    //     );
    //   }

    //   await dispatch("handlePolling", {
    //     loginField,
    //     token,
    //     password,
    //     skinId,
    //   });
    // }
  },

  // async handlePolling(
  //   { dispatch },
  //   {
  //     token = null,
  //     loginField = null,
  //     password = null,
  //     skinId = null,
  //     statusCode = 503,
  //   }
  // ) {
  //   if (statusCode === 503) {
  //     let timeout = 1000;
  //     setTimeout(async () => {
  //       await dispatch("authSkinPolling", {
  //         token,
  //         loginField,
  //         password,
  //         skinId,
  //       });
  //     }, timeout);
  //   }
  // },

  async signUpPlayer([dispatch, commit], token) {},

  async getUserInfo({ dispatch, commit }, userId) {
    let promises = [
      // TODO passar accesstoken
      userRepository.getUser(userId),
      getSettings(userId),
      getBalance(userId),
      getTokens(userId),
      userRepository.getAvatar(userId),
    ];

    Promise.all(promises)
      .then(([user, settings, credit, tokens, avatar]) => {
        // user.data, settings.data, credit.data;

        commit("userInfo/mutateUser", user.data, { root: true });
        commit("userInfo/mutateSettings", settings.data, { root: true });
        commit("userInfo/mutateCredit", credit.data, { root: true });
        commit(
          "userInfo/mutateAvatarCustom",
          avatar.data && avatar.data.photo ? avatar.data.photo : null,
          { root: true }
        );
        commit("userInfo/mutateTokens", tokens.data, { root: true });
      })
      .catch((err) => {
        console.error(err);
        dispatch("cleanUpAuth");
        /* TODO does this work ?? */
        dispatch("socket/destroySocket", null, { root: true });
        if (this._vm.$route.name === "Login") {
          throw err;
        } else {
          this._vm.$router.push("Login");
        }
      });
  },

  updateAccessToken({ commit }, accessToken) {
    commit("mutateAccessToken", accessToken);
  },

  updateRefreshToken({ commit }, refreshToken) {
    commit("setRefreshToken", refreshToken);
  },

  updateDeviceId({ commit }, deviceId) {
    commit("mutateDeviceId", deviceId);
  },

  updateIsAuthenticated({ commit, state }, isAuthenticated) {
    commit("setIsAuthenticated", isAuthenticated);
    userRepository.trackVisit(state.userId).then(() => {
      console.log("Tracked visit.");
    });
  },

  updateUserId({ commit }, userId) {
    commit("mutateUserId", userId);
  },

  updateExternalUserId({ commit }, externalUserId) {
    commit("mutateExternalUserId", externalUserId);
  },

  setUpAuth({ commit }, auth) {
    console.log("setUpAuth");
    console.log("auth", auth);

    commit("mutateAccessToken", auth.accessToken);
    commit("setRefreshToken", auth.refreshToken);
    commit("mutateUserId", auth.userId);
    commit("mutateExternalUserId", auth.externalUserId);
    commit("setIsAuthenticated", true);
    commit("mutateNumContract", auth.numContract);
    commit("setSessionId", auth.sessionId);
    commit("mutateDeviceId", auth.deviceId);
  },

  cleanUpAuth({ commit }) {
    commit("mutateAccessToken", null);
    commit("setRefreshToken", null);
    commit("mutateUserId", null);
    commit("mutateExternalUserId", null);
    commit("setIsAuthenticated", null);
    commit("mutateNumContract", null);
    commit("setSessionId", null);
    Cookies.remove("VIRTUAL_ROOMS_PLAYER_AUTH_COOKIE");
  },
};

// mutations
const mutations = {
  mutateTokens(state, at, rt) {
    if (at) {
      state.accessToken = at;
    }
    if (rt) {
      state.refreshToken = rt;
    }
  },

  mutateAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },

  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },

  mutateDeviceId(state, deviceId) {
    state.deviceId = deviceId;
  },

  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  mutateUserId(state, userId) {
    state.userId = userId;
  },

  mutateExternalUserId(state, externalUserId) {
    state.externalUserId = externalUserId;
  },

  mutateNumContract(state, numContract) {
    state.numContract = numContract;
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
