const events = {
  SESSION_INFO: "session:info",
  SYSTEM_DISABLED: "system:disabled",

  NEW_MESSAGE: "new:message",
  NEW_MESSAGE_REACTION: "new:message:reaction",
  REMOVE_MESSAGE_REACTION: "remove:message:reaction",

  CHAT_USERS: "chat:users",
  CHAT_MESSAGES: "chat:messages",

  USER_JOINED: "user:joined",
  USER_LEFT: "user:left",
  USER_BANNED: "user:banned",

  ROOM_DISABLED: "room:disabled",
  ROOM_MESSAGE: "room:message",
  ROOM_MESSAGE_REACTION: "room:message:reaction",
};

export { events };
