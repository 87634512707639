<template>
  <div class="pwaAndroid bubble">
    <div class="pwaAndroid__overlay" @click="$emit('closePWAModal')"></div>
    <button
      @click="$emit('closePWAModal')"
      class="text-xl absolute right-1 -top-2 p-2"
      style="filter: drop-shadow(0 1px 3px rgb(0 0 0 / 1))"
    >
      &times;
    </button>
    <div class="w-full flex flex-col items-center">
      <img
        src="/pwa/images/icons/icon-144x144.png"
        alt="Virtual Rooms Logo"
        class="w-16 rounded-xl mx-auto"
      />
      <p class="w-fit text-lg mt-1 mb-3">Virtual Rooms</p>

      <p class="mt-2 text-sm">
        Per aggiungere Virtual Rooms alla schermata iniziale clicca:
      </p>
      <button class="h-14" @click="installApp()">
        <PlayStoreBadgeB />
      </button>

      <div class="ml-auto mt-4 text-sm">
        <button
          class="px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium text-white border-b border-white"
          @click="dontShowPWAModalAgain()"
        >
          Non vedere più
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PlayStoreBadgeB from "./utils/PlayStoreBadgeB.vue";

export default {
  name: "PWAAndroid",

  components: { PlayStoreBadgeB },

  props: ["deferredPrompt"],

  // data() {
  //    return {
  //       deferredPrompt: null,
  //    };
  // },

  // mounted() {
  //    window.addEventListener("beforeinstallprompt", (e) => {
  //       e.preventDefault();
  //       // Stash the event so it can be triggered later.
  //       this.deferredPrompt = e;
  //    });
  // },

  methods: {
    async installApp() {
      this.deferredPrompt.prompt();
    },

    dontShowPWAModalAgain() {
      this.$emit("closePWAModal");
      this.$store.dispatch("appSettings/updateShowPwa", false);
    },
  },
};
</script>

<style lang="postcss" scoped>
.pwaAndroid {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  max-height: 83.5%;
  margin: 2.5%;
  width: 95%;
}

.pwaAndroid__overlay {
  top: 2.5rem;
  height: calc(100% - 2.5rem);
  z-index: -1;
  @apply bg-gray-900 bg-opacity-50 w-full fixed left-0;
}

.bubble {
  background-color: theme(colors.gray.900);
  border-radius: 5px;
  box-shadow: 0 0 6px #111827;
  display: inline-block;
  padding: 1.5rem 1rem;
  /* position: relative; */
  vertical-align: top;
  font-size: 0.9rem;
  max-height: 88.5%;
}

.bubble::before {
  background-color: theme(colors.gray.900);
  content: "\00a0";
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  bottom: -7.5px;
  left: calc(50% - 16px);
  transform: rotate(47deg) skew(5deg);
  -moz-transform: rotate(47deg) skew(5deg);
  -ms-transform: rotate(47deg) skew(5deg);
  -o-transform: rotate(47deg) skew(5deg);
  -webkit-transform: rotate(47deg) skew(5deg);
  box-shadow: 2px 2px 2px 0 #111827;
}
</style>
