var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-wrapper",
      class: { "chat-is-open": _vm.isOpen, fullscreen: _vm.isInFullscreen },
      style: _vm.keyboardHeight
        ? `height: calc(100% - 2.5rem - ${_vm.keyboardHeight})`
        : null,
      on: { click: _vm.closeMenu },
    },
    [
      _c("ChatBtn", {
        staticClass: "chat-btn",
        attrs: {
          disabled: !_vm.isReady,
          isOpen: _vm.isOpen,
          screenSize: _vm.screenSize,
        },
        on: { "change-chat-visibility": _vm.chatVisibilityHandler },
      }),
      _vm.isReady && _vm.isOpen
        ? _c("div", { staticClass: "chat-container" }, [
            _c(
              "div",
              { staticClass: "chat-title" },
              [
                _c("SectionTitle", {
                  staticClass: "w-full py-2",
                  attrs: { title: "chat" },
                }),
                _vm.screenSize === "desk" ||
                (_vm.screenSize !== "desk" && _vm.isOpen)
                  ? _c(
                      "button",
                      {
                        staticClass: "close-chat-btn",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.chatVisibilityHandler.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v(" × ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "chat" },
              [
                _c("div", { staticClass: "chat__header" }, [
                  _c(
                    "button",
                    {
                      staticClass: "tab",
                      class: { current: _vm.currentTab === "geral" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.currentTab !== "geral"
                            ? (_vm.currentTab = "geral")
                            : null
                        },
                      },
                    },
                    [_vm._v(" TUTTI ")]
                  ),
                  _vm.hasBarRouteParams
                    ? _c(
                        "button",
                        {
                          staticClass: "tab min-w-0",
                          class: { current: _vm.currentTab === "bar" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.currentTab !== "bar"
                                ? (_vm.currentTab = "bar")
                                : null
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded-full gradient-blue relative",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "h-8 w-8 rounded-full object-cover",
                                style:
                                  _vm.image === "/img/BAR_2.png"
                                    ? "filter: invert(1); padding: 0 0.15rem"
                                    : "",
                                attrs: { src: _vm.image },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "status-circle container gradient-blue",
                                },
                                [
                                  _c("div", {
                                    staticClass: "status-circle content",
                                    style:
                                      _vm.status === "on"
                                        ? "background-color: #44b90f"
                                        : "background-color: red",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "w-fit max-w-4/5 truncate" },
                            [_vm._v(" " + _vm._s(_vm.visibleRoomName) + " ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "keep-alive",
                  [
                    _c(_vm.chatComp, {
                      key: _vm.currentTab,
                      tag: "component",
                      staticClass: "chat__body",
                      attrs: {
                        keyboardHeight: _vm.keyboardHeight,
                        room:
                          this.currentTab === "bar" && _vm.hasBarRouteParams
                            ? _vm.roomName
                            : _vm.geralChatName,
                      },
                      on: { "change-room-status": _vm.changeRoomStatusHandler },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : !_vm.isReady && _vm.isOpen
        ? _c("p", [_vm._v("Caricando...")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }