var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "rounded-btn",
      class: [`w-${_vm.size} h-${_vm.size} ${_vm.bgColor}`],
      attrs: { disabled: _vm.disabled },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("btn-clicked")
        },
      },
    },
    [_vm._t("content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }