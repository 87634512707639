<template>
  <div id="wallet-display">
    <button
      class="button grid grid-cols-3 items-center"
      @click.prevent="creditClick"
    >
      <p v-if="!hideTitles" class="w-fit text-sm text-gray-400">Wallet</p>
      <span class="col-span-2" :class="{ 'col-span-full': hideTokens }">
        <p class="text-base w-fit ml-auto">
          {{ displayCredits ? formatCredit : hideCredit }}
        </p>
      </span>
    </button>
    <div
      class="button grid grid-cols-3 items-center cursor-pointer"
      ref="tooltip-label"
      @mousemove="handleTooltip"
      @mouseleave="hideTooltip"
      @click.prevent="goToTokensView"
      v-if="!hideTokens"
    >
      <!-- @touchend="(e) => e.preventDefault()" -->
      <p class="w-fit text-sm text-gray-400">Tokens</p>
      <span class="flex justify-end col-span-2 relative">
        <span class="text-base" @click.prevent="handleTooltip">
          <!-- @touchend="hideTooltip" -->
          {{ tokens }}
        </span>
        <img
          class="w-4 h-4 ml-1 my-auto -mr-1.5"
          src="/img/vrtoken.png"
          alt="Virtual Rooms token icon"
          @click.prevent="handleTooltip"
        />
        <div ref="tooltip" class="tooltip">
          <!-- <span
            class="w-screen h-screen fixed -top-2 left-0"
            style="z-index: 9999"
            @click.prevent="hideTooltip"
          ></span> -->
          Puoi acquistare fantastiche funzionalità con il nostro incredibile
          token.
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "WalletDisplay",

  props: {
    hideTokens: {
      type: Boolean,
      default: false,
    },
    hideTitles: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formatCredit() {
      return this.$options.filters.money(
        this.$store.getters["userInfo/getCredit"]
      );
    },

    tokens() {
      return this.$store.getters["userInfo/getTokens"];
    },

    hideCredit() {
      let hidder = this.$options.filters.money(1);
      let a = hidder.substring(5, hidder.length);
      return "**.***,** " + a;
    },

    displayCredits() {
      return this.$store.getters["getShowCredit"];
    },
  },

  methods: {
    goToTokensView() {
      this.$router.push({ name: "TokensMovements" });
      this.$root.$emit("closeMenu");
    },

    creditClick() {
      this.$store.commit("updateShowCredit", !this.displayCredits);
    },

    handleTooltip(e) {
      const menu = document.getElementById("menu-container");
      const label = this.$refs["tooltip-label"];
      const tooltip = this.$refs["tooltip"];

      this.$nextTick(() => {
        tooltip.style.display = "block";
        tooltip.style.position = "absolute";
        tooltip.style.zIndex = "9999";

        this.$nextTick(() => {
          let x, y;

          if (e.type === "mousemove") {
            x = e.clientX - label.getBoundingClientRect().left + window.scrollX;
            y = e.clientY - label.getBoundingClientRect().top + window.scrollY;
          } else if (e.type === "touchstart") {
            const touch = e.touches[0];
            x =
              touch.clientX -
              label.getBoundingClientRect().left +
              window.scrollX;
            y =
              touch.clientY -
              label.getBoundingClientRect().top +
              window.scrollY;
          }

          const tooltipWidth = tooltip.offsetWidth;
          const tooltipHeight = tooltip.offsetHeight;
          const menuWidth = menu.offsetWidth;
          const menuHeight = menu.offsetHeight;

          // Check if tooltip overflows on the right
          // if (x + tooltipWidth > menuWidth) {
          tooltip.style.left = x - tooltipWidth + "px";
          // } else {
          //   tooltip.style.left = x + "px";
          // }

          // Check if tooltip overflows on the bottom
          // if (y + tooltipHeight > menuHeight) {
          tooltip.style.top = y - tooltipHeight - 10 + "px";
          // } else {
          //   tooltip.style.top = y + "px";
          // }
        });
      });
    },

    hideTooltip(e) {
      const tooltip = this.$refs["tooltip"];
      tooltip.style.display = "none";
    },
  },
};
</script>

<style lang="postcss" scoped>
#wallet-display {
  width: 100%;
  width: -moz-available; /* For Mozzila */
  width: -webkit-fill-available; /* For Chrome */
  @apply ml-auto flex flex-col justify-center;
}

@media (hover: hover) {
  #wallet-display .button:hover {
    text-shadow: 0 0 1px #ffffff;

    p {
      @apply transform scale-105;
    }
  }
}

.credit-container {
  padding: 0 0.5rem;
  /* @apply border border-gray-600; */

  img {
    height: 1rem;
    width: 1rem;
  }
}

.tooltip {
  width: 250px;
  @apply hidden text-sm px-4 rounded-lg py-0.5 bg-gray-700 relative;

  @screen xs {
    width: 300px;
  }
}
</style>
