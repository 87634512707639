<template>
  <div
    :key="keyboardHeight"
    class="messages-wrapper"
    ref="messagesWrapper"
    :style="
      keyboardHeight && taHeight
        ? `flex: 0 1 calc(100% - ${keyboardHeight}px - ${taHeight}px)`
        : null
    "
  >
    <div
      class="messages-container"
      v-if="
        msgs.length > 0 && !isUserBanned && isSystemEnabled && isRoomEnabled
      "
    >
      <MessageBalloon
        v-for="(msg, index) in msgs"
        :color="nicknameToHex[msg.from]"
        :key="msg.uuid ? msg.uuid : msg.from + '_' + msg.date"
        :content="msg.content"
        :sent="msg.from === nickname"
        :nickname="msg.from"
        :img="getUpdatedUserImg(msg.from)"
        :adminMessage="msg.admin"
        :sameSenderAsPreviousBalloon="isSameSenderAsPreviousBalloon(msg, index)"
        :lastSenderMessage="isLastSenderMessage(msg, index)"
        :time="getTime(msg.date)"
        :reactions="msg.reactions"
        @double-click-detected="doubleClickDetectedHandler(msg)"
      />
    </div>
    <div
      v-else-if="!isRoomEnabled || !isSystemEnabled"
      class="w-full h-full flex justify-center items-center"
    >
      <p class="w-fit text-gray-400">La chat è attualmente disabilitata.</p>
    </div>
    <div
      v-else-if="isUserBanned"
      class="w-full h-full flex justify-center items-center"
    >
      <p class="w-fit text-gray-400">Sei stato bandito da questa stanza.</p>
    </div>
    <div
      v-else-if="!isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <p class="w-fit text-gray-400">
        Nessun messaggio è stato ancora inviato...
      </p>
    </div>
    <div
      v-else-if="isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <p class="w-fit text-gray-400">Loading...</p>
    </div>
  </div>
</template>

<script>
import MessageBalloon from "@/chat/components/messages/MessageBalloon.vue";
export default {
  name: "messagesWrapper",

  components: {
    MessageBalloon,
  },

  props: {
    msgs: {
      type: Array,
      default: [],
    },
    scrollDown: {
      type: Boolean,
      default: false,
    },
    scrollBehavior: {
      type: String,
      default: "auto",
    },
    isUserBanned: {
      type: Boolean,
      default: false,
    },
    isSystemEnabled: {
      type: Boolean,
      default: true,
    },
    isRoomEnabled: {
      type: Boolean,
      default: true,
    },

    keyboardHeight: {
      type: Number,
      default: 0,
    },
    taHeight: {
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      firstEntry: true,
      moreMessages: false,
      userImages: {},
      nicknameToHex: {},
    };
  },

  computed: {
    nickname() {
      return this.$store.getters["userInfo/getNickname"];
    },
  },

  watch: {
    scrollDown: function (val) {
      if (val) {
        this.scrollToBottom(this.$refs.messagesWrapper);
      }
    },

    "msgs.length": function (val) {
      this.setHexToPlayers();
      if (this.firstEntry) {
        this.firstEntry = false;
        this.scrollToBottom(this.$refs.messagesWrapper);
        return;
      }

      if (val > 0) {
        let newMessage = this.msgs[val - 1];
        //if container is overflowing
        if (newMessage.from === this.nickname) {
          this.scrollToBottom(this.$refs.messagesWrapper);
        } else {
          // new  message and the message is not mine
          const userHasNewImg =
            newMessage.userImage !== this.userImages[newMessage.from];
          if (userHasNewImg) {
            this.userImages[newMessage.from] = newMessage.userImage;
          }
          if (
            this.$refs.messagesWrapper.scrollHeight -
              this.$refs.messagesWrapper.scrollTop !==
            this.$refs.messagesWrapper.clientHeight
          ) {
            this.$emit("newMessages");
          }
        }
      }
    },
  },

  mounted() {},

  methods: {
    setHexToPlayers() {
      const hexColors = [
        "#FFDB58",
        "#CC5500",
        "#FF6F61",
        "#E6E6FA",
        "#40E0D0",
        "#C8A2C8",
        "#BCB88A",
        "#708090",
        "#E0FFFF",
      ];
      const shuffledColors = [...hexColors].sort(() => Math.random() - 0.5);
      const nicknames = [...new Set(this.msgs.map((msg) => msg.from))];

      nicknames.forEach((nickname, index) => {
        if (!this.nicknameToHex[nickname]) {
          this.nicknameToHex = {
            ...this.nicknameToHex,
            [nickname]: shuffledColors[index % shuffledColors.length],
          };
        }
      });
    },
    getUpdatedUserImg(nickname) {
      if (this.userImages[nickname]) return this.userImages[nickname];
      let userMsgs = this.msgs.filter((msg) => msg.from == nickname);

      this.userImages[nickname] = userMsgs[userMsgs.length - 1].userImage;
      return this.userImages[nickname];
    },

    doubleClickDetectedHandler(msg) {
      if (!msg.admin) {
        this.$emit("reaction-update", msg.uuid);
      }
    },

    scrollToBottom(elem) {
      this.$nextTick(() => {
        elem.scroll({ top: elem.scrollHeight, behavior: this.scrollBehavior });
      });
    },
    isSameSenderAsPreviousBalloon(msg, index) {
      return index > 0 ? msg.from == this.msgs[index - 1].from : false;
    },
    isLastSenderMessage(msg, index) {
      if (!this.msgs[index + 1]) return true;
      return this.msgs[index + 1].from != msg.from;
    },
    getTime(dateString) {
      const date = new Date(dateString);
      const hour = date.getHours();
      const minutes = date.getMinutes();
      return `${hour < 10 ? "0" + hour : hour}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
    },
  },
};
</script>

<style lang="postcss" scoped>
.messages-wrapper {
  @apply relative min-h-0 flex-1 overflow-y-auto;
  .messages-container {
    @apply space-y-2 py-4 px-3;
  }
}
</style>
