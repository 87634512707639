var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", {
      staticClass: "modal__overlay",
      on: {
        click: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    }),
    _vm.entry
      ? _c(
          "div",
          {
            staticClass: "modal__container",
            class: { in: _vm.opening, out: _vm.closing },
          },
          [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _vm._t("header"),
                !_vm.hideX
                  ? _c(
                      "button",
                      {
                        staticClass: "modal__close-btn",
                        on: { click: _vm.closeHandler },
                      },
                      [_vm._v(" × ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }