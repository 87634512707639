<template></template>

<script>
export default {
  name: "analyticsConfig",

  methods: {
    userIdAnalyticsConfig(userId) {
      this.$gtag.set("userId", userId);
      const iPhoneModel = this.iPhoneModelConfig();
      if (iPhoneModel) {
        console.log(iPhoneModel);
        this.$gtag.set("dimension2", iPhoneModel);
      }
    },

    iPhoneModelConfig() {
      // Check if we are seeing an iPhone at all by looking at the user agent
      if (/iPhone/.test(navigator.userAgent) && !window.MSStream) {
        // Get details about the current device
        const currentDeviceInfo = JSON.stringify({
          width:
            window.screen.width > window.screen.height
              ? window.screen.height
              : window.screen.width,
          height:
            window.screen.width > window.screen.height
              ? window.screen.width
              : window.screen.height,
          ratio: window.devicePixelRatio,
        });

        // This is our "database" of possible device configurations
        const database = {
          "2G/3G/3GS": {
            width: 320,
            height: 480,
            ratio: 1,
          },
          "4/4S": {
            width: 320,
            height: 480,
            ratio: 2,
          },
          "5/5S/5C/SE": {
            width: 320,
            height: 568,
            ratio: 2,
          },
          "6/6S/7/8/SE 2": {
            width: 375,
            height: 667,
            ratio: 2,
          },
          "6+/6S+/7+/8+": {
            width: 414,
            height: 736,
            ratio: 3,
          },
          "X/XS/11 Pro/12 mini/13 mini": {
            width: 375,
            height: 812,
            ratio: 3,
          },
          "XR/11": {
            width: 414,
            height: 896,
            ratio: 2,
          },
          "XS Max/11 Pro Max": {
            width: 414,
            height: 896,
            ratio: 3,
          },
          "12/12 Pro/13/13 Pro": {
            width: 390,
            height: 844,
            ratio: 3,
          },
          "12 Pro Max/13 Pro Max": {
            width: 428,
            height: 926,
            ratio: 3,
          },
        };

        // Loop through our database and compare configurations to our current device
        // Return the device name if a match is found
        for (const model in database) {
          if (JSON.stringify(database[model]) == currentDeviceInfo) {
            return "iPhone " + model;
          }
        }

        return null;
      }

      return null;
    },
  },
};
</script>

<style></style>
