<template>
  <button
    @click="changeChatVisibility"
    class="bg-gray-900 pr-2 pl-3 py-2.5 rounded-lg"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      class="w-5 h-5 stroke-current transform"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
      />
    </svg>
  </button>
</template>

<script>
import RoundedBtn from "@/chat/components/buttons/RoundedBtn.vue";

export default {
  name: "ChatBtn",

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    screenSize: {
      type: String,
      default: "mob",
    },
  },

  components: { RoundedBtn },

  methods: {
    changeChatVisibility() {
      this.$emit("change-chat-visibility");
    },
  },
};
</script>

<style lang="postcss" scoped>
@media (hover: hover) {
  button:hover {
    svg {
      -webkit-filter: drop-shadow(0 0 1px #ffffff);
      filter: drop-shadow(0 0 1px #ffffff);
      @apply scale-110;
    }
  }
}
</style>
