var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("picture", [
    this.enableWebp
      ? _c("source", { attrs: { srcset: _vm.webpSrc, type: "image/webp" } })
      : _vm._e(),
    _c("img", { class: _vm.classList, attrs: { src: _vm.original, alt: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }