import httpClient from "@/helpers/httpClient";

const basePath = "users";

export default {
  getUser(id) {
    return httpClient().get(`${basePath}/${id}`);
  },

  updateUser(id, body) {
    return httpClient().put(`${basePath}/${id}`, body);
  },

  getAvatar(id) {
    return httpClient().get(`${basePath}/${id}/avatar`);
  },

  updateAvatar(id, body) {
    return httpClient().patch(`${basePath}/${id}/avatar`, body);
  },

  trackVisit(id) {
    return httpClient().post(`${basePath}/${id}/track-visits`, {});
  },
};
