import { io } from "socket.io-client";
import { events } from "../../helpers/socketEvents";

// initial state
const state = () => ({
  currentLocation: null,
  socket: null,
  rewardEventData: null,
  notifications: [],
  currentNotification: null,
  timeoutId: null,
});

// getters
const getters = {};

// actions
const actions = {
  async initializeSocket({ rootState, commit }) {
    const query = {
      clientAppLocation: "MAP",
      numContract: rootState.auth.numContract,
      userId: rootState.auth.externalUserId,
    };

    const url = `${process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL}/events`;

    const socket = io(url, {
      transports: ["websocket"],
      maxConnections: 1,
      withCredentials: true,
      autoConnect: false,
      query,
    });

    commit("setSocket", socket);
  },

  async init({ state, dispatch }) {
    try {
      await dispatch("initializeSocket");
      await dispatch("socketHandlers");
    } catch (error) {
      console.error(error);
    }

    state.socket.connect();
  },

  socketHandlers({ state, dispatch, commit }) {
    state.socket.on(events.NOTIFICATION, (data) => {
      console.log("data", data);
      if (!state.currentNotification) {
        //show notification
        commit("setNotification", data);
        const timeout = setTimeout(() => {
          dispatch("handleNextNotification");
        }, 5000);
        commit("setTimeoutId", timeout);
      } else {
        commit("addNotification", data);
      }
    });
    state.socket.on(events.WIN, (data) => {
      console.log("win", data);
    });
    state.socket.on(events.EVENT_VALIDATION_ERROR, (data) => {
      console.log("event:validation:error", data);
    });
    state.socket.on(events.REWARD, (data) => {
      if (state.rewardEventData) {
        commit("clearRewardEventData");
        setTimeout(() => {
          commit("setRewardEventData", data);
        }, 1000);
      } else {
        commit("setRewardEventData", data);
      }
    });
  },

  emitChangeLocation({ state, commit }, [location, extra = null]) {
    try {
      if (state.socket && state.currentLocation !== location) {
        commit("setCurrentLocation", location);
        let data = { clientAppLocation: location };
        if (extra) {
          data = { ...data, ...extra };
        }
        state.socket.emit(events.LOCATION_CHANGE, data);
      }
    } catch (err) {
      console.error(err);
    }
  },

  emitAckReward({ state }) {
    try {
      state.socket.emit(events.ACK_REWARD);
    } catch (err) {
      console.error(err);
    }
  },

  handleNextNotification({ state, dispatch, commit }) {
    commit("shiftNotification");
    if (state.currentNotification) {
      const timeout = setTimeout(() => {
        dispatch("handleNextNotification");
      }, 5000);
      commit("setTimeoutId", timeout);
    }
  },

  clearCurrentNotification({ state, dispatch, commit }) {
    //cleartimeout
    //ref https://dev.to/dillionmegida/how-to-cancel-a-settimeout-in-javascript-l2p
    commit("removeCurrentNotification");
    if (state.notifications.length > 0) {
      dispatch("handleNextNotification");
    }
  },

  destroySocket({ state, commit }) {
    if (state.socket) {
      state.socket.off();
      state.socket.disconnect();
      commit("clearSocket");
    }
  },
};

// mutations
const mutations = {
  setCurrentLocation(state, location) {
    state.currentLocation = location;
  },

  setSocket(state, socket) {
    state.socket = socket;
  },

  setRewardEventData(state, data) {
    state.rewardEventData = data;
  },

  clearRewardEventData(state) {
    state.rewardEventData = null;
  },

  addNotification(state, notification) {
    state.notifications.push(notification);
  },

  setNotification(state, notification) {
    state.currentNotification = notification;
  },

  shiftNotification(state) {
    state.currentNotification = state.notifications.shift();
  },

  removeCurrentNotification(state) {
    if (state.currentNotification) {
      state.currentNotification = null;
      clearTimeout(state.timeoutId);
      state.timeoutId = null;
    }
  },

  setTimeoutId(state, id) {
    state.timeoutId = id;
  },

  clearSocket(state) {
    state.socket = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
