class LoginError extends Error {
  constructor(message, code) {
    super(message);
    this.name = "LoginError";
    this.code = code;
  }
}

class AuthError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthError";
  }
}

export { LoginError, AuthError };
