import { io } from "socket.io-client";

const url = `${process.env.VUE_APP_CHAT_SOCKET_SERVER_URL}/chat`;

function init(data) {
  return io(url, {
    transports: ["websocket"],
    maxConnections: 1,
    withCredentials: true,
    autoConnect: false,
    query: data,
  });
}

export { init };
