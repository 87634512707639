var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "hammer",
          rawName: "v-hammer:swipe.up",
          value: _vm.menuClose,
          expression: "menuClose",
          arg: "swipe",
          modifiers: { up: true },
        },
      ],
      staticClass: "menu-container",
      class: { "is-closed": !_vm.isOpen },
      attrs: { id: "menu-container" },
    },
    [
      _vm.showModal
        ? _c("QRCode", {
            on: {
              close: function ($event) {
                _vm.showModal = false
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-fit xs:w-1/3 lg:w-fit mx-auto" },
        [
          _c(
            "button",
            {
              staticClass:
                "flex justify-center bg-gray-800 rounded-full p-1 h-20 w-20 lg:w-26 lg:h-26 mx-auto cursor-pointer relative",
              class: { "pointer-events-none": _vm.inGame },
              staticStyle: {
                filter: "drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))",
              },
              on: { click: _vm.imgClickHandler },
            },
            [
              _c("img", {
                staticClass: "object-cover w-full h-full rounded-full",
                attrs: {
                  src: _vm.showAvatar,
                  id: "avatar",
                  alt: "User avatar",
                },
                on: { error: _vm.replaceByDefault },
              }),
              !_vm.inGame
                ? _c(
                    "svg",
                    {
                      staticClass:
                        "h-6 w-6 absolute right-0 bottom-0 z-50 bg-gray-800 rounded-full",
                      staticStyle: {
                        filter: "drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))",
                      },
                      attrs: {
                        fill: "none",
                        viewBox: "0 0 24 24",
                        stroke: "#fff",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "1",
                          d: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "1",
                          d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "h1",
            {
              staticClass:
                "py-2 xs:py-1.5 lg:py-2 uppercase text-primary text-sm font-bold text-center w-full lg:text-xl truncate lg:mb-2",
            },
            [_vm._v(" " + _vm._s(_vm.showNickname) + " ")]
          ),
          _c("WalletDisplay", { staticClass: "mx-auto lg:mb-2" }),
          _vm.$store.getters["userInfo/getSkinReturnUrl"]
            ? _c(
                "a",
                {
                  staticClass: "ricarica-btn",
                  attrs: {
                    href:
                      _vm.$store.getters["userInfo/getSkinReturnUrl"] +
                      "/account/ricarica-conto.html",
                    target: "_blank",
                  },
                },
                [_vm._v("RICARICA IL TUO CONTO ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "nav",
        { attrs: { id: "nav-router-links" } },
        [
          _vm.$store.getters["userInfo/getSkinReturnUrl"]
            ? _c(
                "a",
                {
                  staticClass: "navigation-link-extended",
                  attrs: {
                    id: "first-nav-el",
                    href: _vm.$store.getters["userInfo/getSkinReturnUrl"],
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$store.getters["userInfo/getSkinName"]
                        ? _vm.$store.getters["userInfo/getSkinName"]
                        : "Casino"
                    )
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "navigation-link-extended",
              attrs: { to: { name: "SlotsBar" } },
              nativeOn: {
                click: function ($event) {
                  return _vm.menuClose()
                },
              },
            },
            [_vm._v("Mappa")]
          ),
          _vm.$route.name === "Bar" &&
          _vm.barName &&
          _vm.barName !==
            _vm.$store.getters["userInfo/getDefaultBarVisibleName"]
            ? _c(
                "a",
                {
                  staticClass:
                    "navigation-link-extended router-link-exact-active",
                  class: {
                    "flex gap-x-4 justify-center items-center":
                      _vm.getBarFavicon("current", _vm.barSlug),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push({
                        name: "Bar",
                        params: { barName: _vm.barSlug },
                      })
                      _vm.menuClose()
                    },
                  },
                },
                [
                  _vm.currentBarFavicon
                    ? _c("img", {
                        staticClass: "w-5 h-5",
                        attrs: { src: _vm.currentBarFavicon, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.barName) + " "),
                ]
              )
            : _vm._e(),
          !!_vm.$store.getters["userInfo/getDefaultBarVisibleName"]
            ? _c(
                "router-link",
                {
                  staticClass: "navigation-link-extended",
                  class: {
                    "flex gap-x-4 justify-center items-center":
                      _vm.getBarFavicon("current", _vm.barSlug),
                  },
                  attrs: {
                    to: {
                      name: "Bar",
                      params: {
                        barName: _vm.$store.getters["userInfo/getDefaultBar"],
                      },
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.menuClose()
                    },
                  },
                },
                [
                  _vm.defaultBarFavicon
                    ? _c("img", {
                        staticClass: "w-10 h-10",
                        attrs: { src: _vm.defaultBarFavicon, alt: "" },
                      })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.getters["userInfo/getDefaultBarVisibleName"]
                      )
                  ),
                ]
              )
            : _vm._e(),
          _vm.isIos &&
          !_vm.isStandalone &&
          (_vm.$store.getters["appSettings/getShowPWA"] == true ||
            _vm.$store.getters["appSettings/getShowPWA"] == undefined)
            ? _c(
                "button",
                {
                  staticClass: "h-12",
                  on: {
                    click: function ($event) {
                      return _vm.installApp()
                    },
                  },
                },
                [_c("AppStoreBadgeB")],
                1
              )
            : _vm._e(),
          _vm.isAndroid &&
          _vm.deferredPrompt &&
          !_vm.isStandalone &&
          (_vm.$store.getters["appSettings/getShowPWA"] == true ||
            _vm.$store.getters["appSettings/getShowPWA"] == undefined)
            ? _c(
                "button",
                {
                  staticClass: "h-12",
                  on: {
                    click: function ($event) {
                      return _vm.installApp()
                    },
                  },
                },
                [_c("PlayStoreBadgeB")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isSlotMail
        ? _c(
            "a",
            {
              staticClass: "text-xs p-1 w-fit",
              class: _vm.isSlotMail ? "third-icon" : "",
              attrs: { id: "mail", href: _vm.webmailUrl, target: "_blank" },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "h-5 w-5 mx-auto",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5",
                      d: "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
                    },
                  }),
                ]
              ),
              _c("span", { staticClass: "text-xs" }, [_vm._v("Slotmail")]),
            ]
          )
        : _vm._e(),
      _c(
        "button",
        {
          staticClass: "w-fit text-xs p-1",
          style: _vm.isPWAInIphone ? "padding-bottom: 0.5rem" : "",
          attrs: { id: "qr-code" },
          on: {
            click: function ($event) {
              _vm.showModal = true
              _vm.menuClose()
            },
          },
        },
        [
          _c(
            "svg",
            {
              staticClass: "h-5 w-5 mx-auto",
              attrs: {
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor",
              },
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "1.5",
                  d: "M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z",
                },
              }),
            ]
          ),
          _c("span", { staticClass: "text-xs" }, [_vm._v("QR Code")]),
        ]
      ),
      _c(
        "button",
        {
          style: _vm.isPWAInIphone ? "padding-bottom: 0.5rem" : "",
          attrs: { id: "logout" },
          on: { click: _vm.logout },
        },
        [
          _c(
            "svg",
            {
              staticClass: "h-5 w-5 mr-1",
              staticStyle: { transform: "scale(-1, 1)" },
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor",
              },
            },
            [
              _c("path", {
                attrs: {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "1.5",
                  d: "M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1",
                },
              }),
            ]
          ),
          _c("span", { staticClass: "text-xs" }, [_vm._v("Logout")]),
        ]
      ),
      _vm.isAgent
        ? _c(
            "a",
            {
              staticClass: "text-xs p-1 w-fit",
              class: _vm.isSlotMail ? "fourth-icon" : "third-icon",
              style: _vm.isPWAInIphone ? "padding-bottom: 0.5rem" : "",
              attrs: {
                id: "go-to-agent",
                href: _vm.backofficeUrl,
                target: "_blank",
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "h-5 w-5 mx-auto",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.3",
                      d: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
                    },
                  }),
                ]
              ),
              _c("span", { staticClass: "text-center" }, [_vm._v("Promotor")]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }