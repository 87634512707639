<template>
  <h1 :class="{ 'bg-inverted': inverted, 'text-gray-900': !inverted }">
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: String,

    inverted: {
      default: false,
    },
  },
};
</script>

<style lang="postcss" scoped>
h1 {
  /* background: -moz-linear-gradient(
    90deg,
    rgba(255, 168, 0, 1) 0%,
    rgba(185, 1, 10, 0.83) 60%,
    rgba(255, 255, 255, 0) 120%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 168, 0, 1) 0%,
    rgba(185, 1, 10, 0.83) 60%,
    rgba(255, 255, 255, 0) 120%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 168, 0, 1) 0%,
    rgba(185, 1, 10, 0.83) 60%,
    rgba(255, 255, 255, 0) 120%
  ); */
  background: rgb(5, 6, 41);
  background: -moz-linear-gradient(
    90deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: linear-gradient(
    90deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  @apply text-base px-4 font-semibold uppercase w-full whitespace-nowrap text-white;

  /* @screen sm {
      padding-top: 0.5%;
      padding-bottom: 0.5%;
      @apply w-screen text-black px-6 font-semibold text-xs tracking-wider;
   } */

  .bg-inverted {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  /* background: -webkit-linear-gradient(
      90deg,
      #ffa800,
      rgba(185, 1, 10, 0.65) 45%,
      hsla(0, 0%, 100%, 0) 88%,
      hsla(0, 0%, 100%, 0) 100%
   );
   background: -moz-linear-gradient(
      90deg,
      #ffa800,
      rgba(185, 1, 10, 0.65) 45%,
      hsla(0, 0%, 100%, 0) 88%,
      hsla(0, 0%, 100%, 0) 100%
   );
   background: linear-gradient(
      90deg,
      #ffa800,
      rgba(185, 1, 10, 0.65) 45%,
      hsla(0, 0%, 100%, 0) 88%,
      hsla(0, 0%, 0%, 0) 100%
   ); */

  /* @screen lg {
      background: -moz-linear-gradient(
         90deg,
         rgba(255, 168, 0, 1) 0%,
         rgba(185, 1, 10, 0.83) 18%,
         rgba(255, 255, 255, 0) 45%
      );
      background: -webkit-linear-gradient(
         90deg,
         rgba(255, 168, 0, 1) 0%,
         rgba(185, 1, 10, 0.83) 18%,
         rgba(255, 255, 255, 0) 45%
      );
      background: linear-gradient(
         90deg,
         rgba(255, 168, 0, 1) 0%,
         rgba(185, 1, 10, 0.83) 18%,
         rgba(255, 255, 255, 0) 45%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @apply pl-7 text-3xl;
   } */
}
</style>
