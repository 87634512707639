<template>
  <div class="welcome" ref="welcome" @click.prevent="$emit('closeIntroVideo')">
    <img
      src="/img/VR_LogoAnim.webp"
      alt="Virtual Rooms logo animation"
      class="welcome__anim"
      ref="welcomeAnimation"
    />
  </div>
</template>

<script>
export default {
  name: "WelcomePage",

  props: {
    displayIntroVideo: {
      type: Boolean,
      default: true,
    },
  },

  // data() {
  //   return {
  //     showIntroVideo: this.$store.getters["getIntroVideo"],
  //   };
  // },

  mounted() {
    if (this.displayIntroVideo) {
      setTimeout(() => {
        this.$refs.welcome.style.opacity = 0;
      }, 6350);
      setTimeout(() => {
        this.$emit("closeIntroVideo");
      }, 7100);
    } else {
      this.$emit("closeIntroVideo");
    }
  },

  destroyed() {
    this.$store.commit("updateDisplayIntroVideo", false);
  },
};
</script>

<style lang="postcss">
.welcome {
  background: rgb(5, 6, 41);
  background: -moz-linear-gradient(
    137deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: -webkit-linear-gradient(
    137deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: linear-gradient(
    137deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#050629",endColorstr="#153c73",GradientType=1);

  z-index: 9999;

  -webkit-transition: opacity 0.75s linear;
  -moz-transition: opacity 0.75s linear;
  -ms-transition: opacity 0.75s linear;
  -o-transition: opacity 0.75s linear;
  transition: opacity 0.75s linear;

  @apply w-full h-full fixed top-0 left-0 flex items-center justify-center;

  .welcome__anim {
    animation: fadein 1.5s;
    -moz-animation: fadein 1.5s; /* Firefox */
    -webkit-animation: fadein 1.5s; /* Safari and Chrome */
    -o-animation: fadein 1.5s; /* Opera */
    /* @apply animate-pulse; */

    @screen lg {
      @apply w-2/5;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
