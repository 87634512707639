<template>
  <button
    class="rounded-btn"
    :class="[`w-${size} h-${size} ${bgColor}`]"
    :disabled="disabled"
    @click.prevent="$emit('btn-clicked')"
  >
    <slot name="content"></slot>
  </button>
</template>

<script>
export default {
  name: "RoundedBtn",

  props: {
    size: {
      type: Number,
      default: 6,
    },

    bgColor: {
      type: String,
      default: "gradient-blue",
    },

    disabled: {
      type: Boolean,
      defaul: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.rounded-btn {
  @apply rounded-full p-2 flex flex-col justify-center items-center;

  &.gradient-blue {
  background: rgb(14, 13, 75);
  background: -moz-linear-gradient(0deg, rgba(14, 13, 75, 1) 25%, #26c6da 75%);
  background: -webkit-gradient(
    linear,
    160deg,
    color-stop(35%, rgba(14, 13, 75, 1)),
    color-stop(100%, #26c6da)
  );
  background: linear-gradient(160deg, rgba(14, 13, 75, 1) 35%, #26c6da 100%);

    &.none {
      @apply bg-transparent;
    }
  }
}
</style>
