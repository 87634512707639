var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settings" }, [
    _c(
      "button",
      {
        staticClass:
          "fixed text-4xl px-3 top-10 right-1 font-light lg:text-4xl lg:top-10 lg:right-0 lg:px-3.5 text-white",
        staticStyle: { "z-index": "1" },
        on: { click: _vm.goBack },
      },
      [_vm._v(" × ")]
    ),
    _c("div", { staticClass: "user-settings" }, [
      _c("div", { staticClass: "lg:overflow-y-auto" }, [
        _c("div", { attrs: { id: "user-avatar" } }, [
          _c(
            "h2",
            {
              staticClass:
                "text-center uppercase text-primary font-bold tracking-wide lg:text-xl",
            },
            [_vm._v(" " + _vm._s(_vm.userInfo.nickname) + " ")]
          ),
          _c(
            "button",
            {
              staticClass: "mx-auto relative rounded-full bg-gray-800",
              staticStyle: {
                filter: "drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.uploadAvatarImg.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-full w-full object-cover",
                attrs: {
                  src: _vm.showAvatar,
                  id: "avatar-cover",
                  alt: "User avatar",
                },
                on: { error: _vm.replaceByDefault },
              }),
              _c(
                "svg",
                {
                  staticClass:
                    "h-6 w-6 md:h-9 md:w-9 absolute right-1 bottom-1 bg-gray-800 rounded-full p-1",
                  staticStyle: {
                    filter: "drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))",
                  },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("input", {
            ref: "imgUpload",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              id: "imgupload",
              accept: "image/png, image/jpeg, image/jpg",
            },
            on: { change: _vm.uploadCustomAvatar },
          }),
          _c(
            "div",
            { staticClass: "flex justify-center md:gap-x-5 gap-2 flex-wrap" },
            [
              _vm._l(_vm.avatars, function (avatar) {
                return _c("img", {
                  key: avatar.name,
                  staticClass: "avatar-img",
                  class: {
                    "border-secondary": _vm.userInfo.avatar == avatar.name,
                    "border-transparent": _vm.userInfo.avatar != avatar.name,
                  },
                  staticStyle: {
                    filter: "drop-shadow(3px 3px 2px rgb(0 0 0 / 0.5))",
                  },
                  style: { "background-color": avatar.bg },
                  attrs: { src: avatar.src },
                  on: {
                    click: function ($event) {
                      return _vm.changeAvatar(avatar.name)
                    },
                    error: function ($event) {
                      avatar.src = "/img/default_img.jpg"
                    },
                  },
                })
              }),
              _vm.showCustomAvatar
                ? _c("img", {
                    staticClass:
                      "w-12 h-12 md:w-16 md:h-16 object-cover border-2 rounded-full shadow-xl lg:w-20 lg:h-20",
                    class: {
                      "border-secondary":
                        _vm.userInfo.avatar == _vm.showCustomAvatar,
                      "border-transparent":
                        _vm.userInfo.avatar != _vm.showCustomAvatar,
                      "border-dashed":
                        _vm.avatarLoading == _vm.showCustomAvatar,
                    },
                    attrs: { src: _vm.showCustomAvatar },
                    on: {
                      click: function ($event) {
                        return _vm.changeCustomAvatar()
                      },
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "lg:flex-1 lg:mt-10" }, [
          !_vm.isSkin
            ? _c("div", { attrs: { id: "user-data" } }, [
                _c("div", { staticClass: "input-container" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "name" } },
                    [_vm._v("Nome:")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userInfo.name,
                        expression: "userInfo.name",
                      },
                    ],
                    staticClass: "rounded-full bg-gray-700 px-5 py-1 w-full",
                    attrs: { type: "text", id: "name" },
                    domProps: { value: _vm.userInfo.name },
                    on: {
                      change: function ($event) {
                        return _vm.updateStoreUser("name", _vm.userInfo.name)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.userInfo, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "input-container" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "email" } },
                    [_vm._v("Email:")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userInfo.email,
                        expression: "userInfo.email",
                      },
                    ],
                    staticClass: "rounded-full bg-gray-700 px-5 py-1 w-full",
                    attrs: { type: "text", id: "email" },
                    domProps: { value: _vm.userInfo.email },
                    on: {
                      change: function ($event) {
                        return _vm.updateStoreUser("email", _vm.userInfo.email)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.userInfo, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _c("div", { attrs: { id: "settings-default" } }, [
            _c("div", [
              _c(
                "h2",
                { staticClass: "title", attrs: { id: "radio-input-title" } },
                [_vm._v("Mostra il credito:")]
              ),
              _c("div", { staticClass: "flex space-x-7" }, [
                _c("label", { staticClass: "radio radio-before" }, [
                  _c("span", { staticClass: "radio__label" }, [_vm._v("Sì")]),
                  _c("span", { staticClass: "radio__input" }, [
                    _c("input", {
                      attrs: { type: "radio" },
                      domProps: { checked: _vm.settings.displayCredits },
                      on: {
                        change: function ($event) {
                          return _vm.updateStoreSettings("displayCredits", true)
                        },
                      },
                    }),
                    _c("span", { staticClass: "radio__control" }),
                  ]),
                ]),
                _c("label", { staticClass: "radio radio-before" }, [
                  _c("span", { staticClass: "radio__label" }, [_vm._v("No")]),
                  _c("span", { staticClass: "radio__input" }, [
                    _c("input", {
                      attrs: { type: "radio" },
                      domProps: { checked: !_vm.settings.displayCredits },
                      on: {
                        change: function ($event) {
                          return _vm.updateStoreSettings(
                            "displayCredits",
                            false
                          )
                        },
                      },
                    }),
                    _c("span", { staticClass: "radio__control" }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", [
              _c(
                "h2",
                { staticClass: "title", attrs: { id: "radio-input-title" } },
                [_vm._v(" Dopo il login, preferisci l'accesso a: ")]
              ),
              _c("div", { staticClass: "flex space-x-7" }, [
                _c("label", { staticClass: "radio radio-before" }, [
                  _c("span", { staticClass: "radio__label" }, [
                    _vm._v("Mappa"),
                  ]),
                  _c("span", { staticClass: "radio__input" }, [
                    _c("input", {
                      attrs: { type: "radio" },
                      domProps: { checked: !_vm.settings.goToBar },
                      on: {
                        change: function ($event) {
                          return _vm.updateStoreSettings("goToBar", false)
                        },
                      },
                    }),
                    _c("span", { staticClass: "radio__control" }),
                  ]),
                ]),
                _c("label", { staticClass: "radio radio-before" }, [
                  _c("span", { staticClass: "radio__label" }, [_vm._v("Bar")]),
                  _c("span", { staticClass: "radio__input" }, [
                    _c("input", {
                      attrs: { type: "radio" },
                      domProps: { checked: _vm.settings.goToBar },
                      on: {
                        change: function ($event) {
                          return _vm.updateStoreSettings("goToBar", true)
                        },
                      },
                    }),
                    _c("span", { staticClass: "radio__control" }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { attrs: { id: "user-figure" } }, [
        _c("h2", { staticClass: "title mb-0.5" }, [
          _vm._v("Scegli la tua figura di gioco:"),
        ]),
        _c("div", { staticClass: "figure-container" }, [
          _c(
            "div",
            {
              staticClass:
                "flex justify-center flex-wrap gap-x-1 gap-y-1 w-5/12 overflow-y-auto",
            },
            [
              _vm._l(_vm.figuresF, function (figure) {
                return _c(
                  "button",
                  {
                    key: figure.name,
                    staticClass: "justify-center",
                    on: {
                      click: function ($event) {
                        return _vm.updateFigure(figure)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "avatar-img bg-gray-700 border-transparent",
                      attrs: { src: "../../" + figure.src },
                    }),
                    _c("span", { staticClass: "text-xs" }, [
                      _vm._v(_vm._s(figure.slug)),
                    ]),
                  ]
                )
              }),
              _vm._l(_vm.figuresM, function (figure) {
                return _c(
                  "button",
                  {
                    key: figure.name,
                    staticClass: "justify-center",
                    on: {
                      click: function ($event) {
                        return _vm.updateFigure(figure)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "avatar-img bg-gray-700 border-transparent",
                      attrs: { src: "../../" + figure.src },
                    }),
                    _c("span", { staticClass: "text-xs" }, [
                      _vm._v(_vm._s(figure.slug)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "swiper flex-1 border-l border-gray-500" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.selectedFigure.body, function (body) {
                return _c(
                  "div",
                  { key: body.id, staticClass: "swiper-slide" },
                  [
                    _c("div", { staticClass: "h-full" }, [
                      _c("img", {
                        staticClass: "h-full mx-auto touchable",
                        attrs: { src: "../../" + body.src },
                      }),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "swiper-button-prev" }),
            _c("div", { staticClass: "swiper-button-next" }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }