var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__overlay" }),
    _c("div", { staticClass: "modal__container" }, [
      _c("div", { staticClass: "header-body-container gradient-blue" }, [
        _vm._m(0),
        _c("div", { staticClass: "modal-body" }, [
          !_vm.showInfo
            ? _c(
                "div",
                {
                  staticClass: "daily-reward",
                  class: { "fade-out": _vm.isClaimed },
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-lg xl:text-xl uppercase font-medium tracking-wide text-center",
                    },
                    [_vm._v(" Congratulazioni! ")]
                  ),
                  _c("p", { staticClass: "text-center text-sm xl:text-base" }, [
                    _vm._v(" Hai vinto una ricompensa giornaliera. "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center p-6" },
                    [
                      !_vm.isClaimed
                        ? _c("img", {
                            attrs: { src: "/img/treasure_closed.png", alt: "" },
                          })
                        : _c("div", { staticClass: "relative" }, [
                            _c("img", {
                              staticClass:
                                "opening-box golden-drop-shadow-animation",
                              attrs: { src: "/img/treasure_open.png", alt: "" },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tokens-container flex items-center golden-drop-shadow-animation",
                              },
                              [
                                _c("p", { staticClass: "text-4xl font-bold" }, [
                                  _vm._v(_vm._s(_vm.tokensEarned)),
                                ]),
                                _c("Coin", {
                                  staticStyle: {
                                    transform: "scale(0.35)",
                                    "margin-left": "-20px",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                    ]
                  ),
                ]
              )
            : _c("div", { staticClass: "daily-rewards-info fade-in" }, [
                _c("div", { staticClass: "flex w-full gap-x-4 items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "grid box-border gap-4 min-w-0",
                      class: `grid-cols-${Math.floor(
                        _vm.data.rules.consecutiveRewardDays / 2
                      )}`,
                      style: `flex: ${Math.floor(
                        _vm.data.rules.consecutiveRewardDays / 2
                      )}; grid-template-columns: repeat(${Math.floor(
                        _vm.data.rules.consecutiveRewardDays / 2
                      )}, minmax(0, 1fr));`,
                    },
                    [
                      _vm._l(
                        _vm.data.rules.consecutiveRewardDays - 1,
                        function (i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass:
                                "rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden relative",
                              class: {
                                "blue-shadow-pulsing shine":
                                  i === _vm.dailyRewardData.consecutive,
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        i === _vm.dailyRewardData.consecutive
                                          ? "Oggi"
                                          : `Giorno ${i}`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("img", {
                                staticClass: "mt-4 w-2/3 mx-auto",
                                class: {
                                  "saturate-25":
                                    i !== _vm.dailyRewardData.consecutive &&
                                    _vm.isRewardClaimed(i),
                                  "golden-drop-shadow-animation":
                                    i === _vm.dailyRewardData.consecutive &&
                                    _vm.isRewardClaimed(i),
                                },
                                attrs: {
                                  src: "/img/treasure_open.png",
                                  alt: "",
                                },
                              }),
                              _vm.isRewardClaimed(i)
                                ? _c("img", {
                                    staticClass:
                                      "absolute top-1.5 right-2 w-4 h-4",
                                    class: {
                                      "todays-check":
                                        i === _vm.dailyRewardData.consecutive,
                                    },
                                    attrs: {
                                      src: "/img/icons/CHECK_ICON.png",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-4 gradient-blue-30 px-2 py-0.5",
                                  class: {
                                    "saturate-25":
                                      i !== _vm.dailyRewardData.consecutive &&
                                      _vm.isRewardClaimed(i),
                                  },
                                },
                                [
                                  !_vm.isRewardClaimed(i) ||
                                  i === _vm.dailyRewardData.consecutive
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "flex text-sm xl:text-base font-bold gap-x-1 items-center justify-center",
                                        },
                                        [
                                          _vm._v(
                                            " +" +
                                              _vm._s(
                                                _vm.dailyRewardData.amount
                                              ) +
                                              " "
                                          ),
                                          _vm._m(1, true),
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-xs xl:text-base tracking-wide text-center uppercase tracking-wide",
                                        },
                                        [_vm._v(" Richiesto ")]
                                      ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm.isEven
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-1 h-fit min-w-0 rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden flex flex-col justify-center relative",
                              class: {
                                "blue-shadow-pulsing shine": _vm.consRewardData,
                              },
                            },
                            [
                              _vm.consRewardData
                                ? _c("img", {
                                    staticClass:
                                      "absolute top-1.5 right-2 w-4 h-4",
                                    class: {
                                      "todays-check":
                                        _vm.i ===
                                        _vm.dailyRewardData.consecutive,
                                    },
                                    attrs: {
                                      src: "/img/icons/CHECK_ICON.png",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.dailyRewardData.consecutive ===
                                          _vm.data.rules.consecutiveRewardDays
                                          ? "Oggi"
                                          : `Giorno ${_vm.data.rules.consecutiveRewardDays}`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-1 flex flex-col justify-center mt-6",
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-2/3 mx-auto",
                                    class: {
                                      "golden-drop-shadow-animation":
                                        _vm.consRewardData,
                                    },
                                    attrs: {
                                      src: "/img/treasure-chest.png",
                                      alt: "",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full h-10 gradient-blue-30 mt-6",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tokens-container flex items-center -mt-8 w-fi",
                                          staticStyle: {
                                            transform: "scale(0.25)",
                                            "margin-left": "-2.3em",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-8xl font-bold pt-0.5 mr-6",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2.5" },
                                                [_vm._v("+")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.data.rules
                                                    .consecutiveRewardTokens +
                                                    _vm.data.rules
                                                      .dailyRewardTokens
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                          _c("img", {
                                            staticClass: "h-4 w-4",
                                            attrs: {
                                              src: "/img/golden-token.svg",
                                              alt: "",
                                            },
                                          }),
                                          _c("Coin"),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  !_vm.isEven
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex-1 h-fit min-w-0 rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden flex flex-col justify-center relative",
                          class: {
                            "blue-shadow-pulsing shine": _vm.consRewardData,
                          },
                        },
                        [
                          _vm.consRewardData
                            ? _c("img", {
                                staticClass: "absolute top-1.5 right-2 w-4 h-4",
                                class: {
                                  "todays-check":
                                    _vm.i === _vm.dailyRewardData.consecutive,
                                },
                                attrs: {
                                  src: "/img/icons/CHECK_ICON.png",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticClass:
                                "uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dailyRewardData.consecutive ===
                                      _vm.data.rules.consecutiveRewardDays
                                      ? "Oggi"
                                      : `Giorno ${_vm.data.rules.consecutiveRewardDays}`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-1 flex flex-col justify-center mt-6",
                            },
                            [
                              _c("img", {
                                staticClass: "w-2/3 mx-auto",
                                class: {
                                  "golden-drop-shadow-animation":
                                    _vm.consRewardData,
                                },
                                attrs: {
                                  src: "/img/treasure-chest.png",
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-full h-10 gradient-blue-30 mt-6",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tokens-container flex items-center justify-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-xl font-bold pt-0.5 mr-3",
                                        },
                                        [
                                          _c("span", { staticClass: "mr-1" }, [
                                            _vm._v("+"),
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              _vm.data.rules
                                                .consecutiveRewardTokens +
                                                _vm.data.rules.dailyRewardTokens
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                      _c("img", {
                                        staticClass: "h-7 w-7 mt-1.5",
                                        attrs: {
                                          src: "/img/golden-token.svg",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "modal-footer" }, [
        _vm.showCloseModalBtn
          ? _c(
              "button",
              { staticClass: "claim-btn", on: { click: _vm.claimReward } },
              [_vm._v(" Richiesta ")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h2", [_vm._v("ricompensa giornaliera")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("img", {
        staticClass: "h-4 w-4",
        attrs: { src: "/img/golden-token.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }