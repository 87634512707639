<template>
  <Transition>
    <div v-if="currentNotification" class="notification-container">
      <div v-if="currentNotification.contentType === 'string'">
        <div class="toast" ref="toast">
          <img src="/img/icons/NOTIFICATION_ICON.png" alt="" />
          <p>{{ currentNotification.message }}</p>
          <button @click="clearToast">&times;</button>
        </div>
      </div>
      <div
        v-else-if="currentNotification.contentType === 'html'"
        v-html="currentNotification.message"
      ></div>
    </div>
  </Transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Toasts",

  computed: {
    ...mapState("socket", ["currentNotification"]),
  },

  methods: {
    clearToast() {
      this.$store.dispatch("socket/clearCurrentNotification");
    },
  },
};
</script>

<style lang="postcss" scoped>
.notification-container {
  top: 2.5rem;
  z-index: 1;
  @apply fixed w-full flex items-center flex-col gap-y-2 py-2;
  .toast {
    background: linear-gradient(
      168deg,
      #ff9d00 18.42%,
      #ffa800 47.15%,
      #ffd600 85.28%
    );
    @apply h-fit rounded-lg py-2.5 px-4 text-sm flex gap-x-2 items-center;

    img {
      height: fit-content;
      @apply w-4;
    }

    p {
      @apply text-gray-900 pr-2;
    }

    button {
      @apply text-gray-900 text-xl h-fit pl-2;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
