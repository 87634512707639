import { render, staticRenderFns } from "./WelcomePage.vue?vue&type=template&id=958982de"
import script from "./WelcomePage.vue?vue&type=script&lang=js"
export * from "./WelcomePage.vue?vue&type=script&lang=js"
import style0 from "./WelcomePage.vue?vue&type=style&index=0&id=958982de&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('958982de')) {
      api.createRecord('958982de', component.options)
    } else {
      api.reload('958982de', component.options)
    }
    module.hot.accept("./WelcomePage.vue?vue&type=template&id=958982de", function () {
      api.rerender('958982de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/WelcomePage.vue"
export default component.exports