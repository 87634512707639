import settingsRepository from "@/api/settingsRepository";
import tokensRepository from "@/api/tokensRepository";
import userRepository from "@/api/userRepository";

// initial state
const state = () => ({
  user: null,
  settings: null,
  credit: null,
  customAvatar: null,
  tokens: null,
});

// getters
const getters = {
  getSkinReturnUrl: (state) => {
    return state.user && state.user.skinReturnUrl
      ? state.user.skinReturnUrl
      : null;
  },
  getSkinName: (state) => {
    return state.user && state.user.skinName ? state.user.skinName : null;
  },
  /*
   * always return CURRENT avatar, being a prebuild one or a custom (s3)
   */
  getAvatar: (state) => {
    let defaultAvatar = "/img/avatars/genie.png";

    if (!state.user || !state.user.avatar) {
      return defaultAvatar;
    }

    try {
      //IT IS used to trigger the exception on failed URL ( no longer available/network issues/deleted at bucket)
      const url = new URL(state.user.avatar);

      if (url) {
        if (state.customAvatar == null) {
          state.customAvatar = state.user.avatar;
        }
        return state.user.avatar;
      }
    } catch (e) {
      const avatarPath = `/img/avatars/${state.user.avatar}.png`;
      return avatarPath;
    }

    // try block MAY not return anything
    return defaultAvatar;
  },

  getLocalAvatarPath: (state) => (avatar) => {
    const avatarPath = `/img/avatars/${avatar}.png`;
    return avatarPath;
  },

  getName: (state) => {
    if (state.user) return state.user.name;
    return null;
  },
  getDefaultBar: (state) => {
    if (state.user) return state.settings.defaultBar;
    return null;
  },
  getDefaultBarVisibleName: (state) => {
    if (state.user) return state.settings.defaultBarVisibleName;
    return null;
  },
  getGoToBar: (state) => {
    if (state.user) return state.settings.goToBar;
    return null;
  },
  getNickname: (state) => {
    if (state.user) return state.user.nickname;
    return null;
  },
  getEmail: (state) => {
    if (state.user) return state.user.email;
    return null;
  },

  getUserId: (state) => {
    if (state.user) return state.user.id;
    return null;
  },

  getCredit: (state) => {
    if (state.credit) return state.credit;
    return 0;
  },

  getTokens: (state) => {
    if (state.tokens) return state.tokens;
    return 0;
  },

  getFigureId: (state) => {
    if (state.user) return state.settings.figureId;
    return null;
  },

  getCustomAvatar: (state) => {
    if (state.customAvatar) {
      return state.customAvatar;
    }
    return null;
  },

  getUser: (state) => {
    if (state.user) {
      return state.user;
    }
    return null;
  },

  getSettings: (state) => {
    if (state.settings) {
      return state.settings;
    }
    return null;
  },
};

// actions
const actions = {
  updateSettings(userInfo) {
    return new Promise((resolve, reject) => {
      settingsRepository
        .updateSettings(userInfo.getters["getUserId"], userInfo.state.settings)
        .then(() => {
          resolve("Updated settings API");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateUserInfo({ state }) {
    return new Promise((resolve, reject) => {
      const userInfo = JSON.parse(JSON.stringify(state.user));
      try {
        const url = new URL(userInfo.avatar);
        userInfo.avatar = null;
      } catch (err) {}

      userRepository
        .updateUser(userInfo.id, userInfo)
        .then(() => {
          resolve("Updated user API");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCredit(userInfo) {
    // alert(userInfo.getters["getUserId"]);
    getCredit(userInfo.getters["getUserId"]).then((response) => {
      userInfo.commit("mutateCredit", response.data);
    });
  },

  updateTokens(userInfo) {
    // alert(userInfo.getters["getUserId"]);
    tokensRepository
      .getCredit(userInfo.getters["getUserId"])
      .then((response) => {
        userInfo.commit("mutateTokens", response.data);
      });
  },

  updateAvatar({ state }, form) {
    return new Promise((resolve, reject) => {
      userRepository.updateAvatar(state.user.id, form).then(() => {
        resolve();
      });
    });
  },

  getAvatar(userInfo) {
    // if (userInfo.getters["auth/getIsAuthenticated"]) {
    return new Promise((resolve, reject) => {
      userRepository.getAvatar(userInfo.state.user.id).then((response) => {
        resolve(response.data);
      });
    });
  },
};

// mutations
const mutations = {
  mutateUser(state, user) {
    state.user = user;
  },

  mutateUserKey(state, { key, value }) {
    state.user[key] = value;
  },

  mutateSettings(state, settings) {
    state.settings = settings;
  },

  mutateSettingsKey(state, { key, value }) {
    state.settings[key] = value;
  },

  mutateCredit(state, credit) {
    state.credit = credit.credit;
  },

  mutateTokens(state, tokens) {
    state.tokens = tokens.credit;
  },

  mutateAvatar(state, avatar) {
    state.avatar = avatar;
  },

  mutateAvatarCustom(state, customAvatar) {
    state.customAvatar = customAvatar;
  },

  // deleteTrack(state,uri){
  //     state.playlist.tracks.items = state.playlist.tracks.items.filter( item => item.track.uri !==  uri )
  // },
  // updatePlaylist(state,{playlist_id, name, description, coverImage}){
  //     const playlist = state.playlists.items.find(item => item.id === playlist_id)
  //     playlist.name = name
  //     playlist.description = description
  //     state.playlist.name = name
  //     state.playlist.description = description
  //     state.playlist.images[0].url = coverImage;
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
