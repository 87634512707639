var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "ta", staticClass: "chat__typing-area" },
    [
      _c("div", { staticClass: "shadow h-10 -top-10 left-0" }),
      _vm.showLinkNotification
        ? _c("div", { staticClass: "notification" }, [
            _c("button", [
              _vm._v("La condivisione tramite link non è consentita"),
            ]),
          ])
        : _vm._e(),
      _vm.moreMessages
        ? _c("div", { staticClass: "notification" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.seeNewMessagesClick.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Visualizza i nuovi messaggi ")]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "text-area--container",
          class: _vm.isTextareaFocused ? "border-white" : "border-transparent",
        },
        [
          _c("textarea", {
            ref: "chat-input",
            attrs: { placeholder: "Invia il tuo messagio..." },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.sendMessage.apply(null, arguments)
              },
              focus: _vm.inputFocusHandler,
              blur: function ($event) {
                _vm.isTextareaFocused = false
              },
              input: _vm.handleInput,
            },
          }),
          _c("p", [
            _c(
              "span",
              { class: { "text-red font-medium": _vm.invalidMsgLength } },
              [_vm._v(_vm._s(_vm.msgLength))]
            ),
            _vm._v(" /" + _vm._s(_vm.maxMsgLength) + " "),
          ]),
        ]
      ),
      _c("RoundedBtn", {
        class: { disabled: _vm.msgLength === 0 || _vm.invalidMsgLength },
        attrs: { size: 10 },
        on: { "btn-clicked": _vm.sendMessage },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("img", {
                  staticClass: "pr-0.5",
                  attrs: { src: "/img/icons/SEND_ICON.png" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }