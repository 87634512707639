<script>
export default {
  name: "minWidthMixin834",

  data() {
    return {
      minWidth834: window.innerWidth >= 834,
    };
  },

  methods: {
    startEventMW834() {
      window.addEventListener("resize", this.defineMinWidth834);
    },

    defineMinWidth834() {
      this.minWidth834 = window.innerWidth >= 834;
    },
  },

  created() {
    this.startEventMW834();
  },

  destroyed() {
    window.removeEventListener("resize", this.defineMinWidth834);
  },
};
</script>
<style lang="postcss"></style>
