var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-balloon-wrapper",
      class: { sent: _vm.sent, "same-user": _vm.sameSenderAsPreviousBalloon },
    },
    [
      _vm.lastSenderMessage && !_vm.sent
        ? _c("div", { staticClass: "img-container" }, [
            _c("img", {
              attrs: { src: _vm.img },
              on: { error: _vm.replaceByDefault },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "message-balloon-container unselectable",
          style: _vm.reactions.length > 0 ? "margin-bottom: -12px" : "",
          on: { click: _vm.handleClick },
        },
        [
          !_vm.sameSenderAsPreviousBalloon && !_vm.sent
            ? _c(
                "p",
                {
                  staticClass: "user-nickname",
                  class: {
                    "ml-1": !_vm.sent,
                    "ml-8": !_vm.lastSenderMessage && !_vm.sent,
                  },
                  style: { color: _vm.color },
                },
                [_vm._v(" " + _vm._s(_vm.nickname) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "message-balloon",
              class: {
                "message admin": _vm.adminMessage,
                "message last-received":
                  !_vm.sent && _vm.lastSenderMessage && !_vm.adminMessage,
                "message last-sent":
                  _vm.sent && _vm.lastSenderMessage && !_vm.adminMessage,
                sent: _vm.sent,
                received: !_vm.sent,
                "ml-7": !_vm.sent && !_vm.lastSenderMessage,
              },
              style: {
                background: _vm.backgroundColor,
              },
            },
            [
              _c("p", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.content)),
              ]),
              _c(
                "p",
                {
                  staticClass: "time",
                  class: _vm.sent ? "text-gray-400" : "text-gray-600",
                },
                [_vm._v(" " + _vm._s(_vm.time) + " ")]
              ),
            ]
          ),
          _vm.reactions.length > 0
            ? _c(
                "div",
                {
                  staticClass: "reactions-container",
                  class: {
                    sent: _vm.sent,
                    received: !_vm.sent && !_vm.adminMessage,
                    admin: _vm.adminMessage,
                  },
                  on: { click: _vm.reactionClick },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 150.61 133.46",
                      },
                    },
                    [
                      _c(
                        "defs",
                        [
                          _c(
                            "linearGradient",
                            { attrs: { id: "gradient-blue" } },
                            [
                              _c("stop", {
                                attrs: {
                                  offset: "25%",
                                  "stop-color": "rgb(5, 6, 41)",
                                },
                              }),
                              _c("stop", {
                                attrs: {
                                  offset: "75%",
                                  "stop-color": "rgb(21, 60, 115)",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("path", {
                        staticClass: "white",
                        attrs: {
                          d: "M142,76a13.51,13.51,0,0,0,5-10.88,17,17,0,0,0-.39-3.76,12.57,12.57,0,0,0-12.24-9.72H101.94l3-17.31a19.77,19.77,0,0,0-.26-8.07,64.31,64.31,0,0,0-7.54-18A7.82,7.82,0,0,0,94.2,5.5a16.28,16.28,0,0,0-7.69-2A15.79,15.79,0,0,0,77,6.86a9.4,9.4,0,0,0-3.73,7.47V31.9a.61.61,0,0,1,0,.19c-3,10.31-17.37,27.4-19,29.31l-.11.11L52,63.16a7.85,7.85,0,0,0-7-4.35H11.37A7.87,7.87,0,0,0,3.5,66.68v55.38a7.87,7.87,0,0,0,7.87,7.87H44.93a7.88,7.88,0,0,0,7.48-5.42l6.41,3.2a7.77,7.77,0,0,0,3.47.82l61.82-.06c6.59,0,13.93-5.32,13.93-13a17,17,0,0,0-1.4-6.93,13.91,13.91,0,0,0,5-10.16A13.13,13.13,0,0,0,140.06,92a13.35,13.35,0,0,0,4-9.16A12.09,12.09,0,0,0,142,76Z",
                          transform: "translate(0 0.02)",
                        },
                      }),
                      _c("path", {
                        staticClass: "gradient-blue",
                        attrs: {
                          d: "M146.48,76.7a17.26,17.26,0,0,0,4.13-11.58,20.56,20.56,0,0,0-.46-4.49A16.09,16.09,0,0,0,134.5,48.16H106.21L108.49,35a23.18,23.18,0,0,0-.31-9.49,67.78,67.78,0,0,0-8-19,11.24,11.24,0,0,0-4.27-4A19.77,19.77,0,0,0,86.58,0l0,0A19.3,19.3,0,0,0,74.93,4.05a12.92,12.92,0,0,0-5.12,10.26V31.48C67.39,39,58.13,51.28,52.43,58.15a11.31,11.31,0,0,0-7.49-2.84H11.37A11.38,11.38,0,0,0,0,66.68v55.39a11.38,11.38,0,0,0,11.37,11.37H44.94a11.37,11.37,0,0,0,8.92-4.33l3.43,1.71a11.25,11.25,0,0,0,5,1.19h62c9.29,0,17.43-7.73,17.43-16.54a21,21,0,0,0-.86-6,16.41,16.41,0,0,0,3.43-16.79,16.29,16.29,0,0,0,3.43-9.76A15.92,15.92,0,0,0,146.48,76.7ZM44.94,126.44H11.37A4.37,4.37,0,0,1,7,122.07V66.68a4.37,4.37,0,0,1,4.37-4.37H44.94a4.31,4.31,0,0,1,1.86.43,4.35,4.35,0,0,1,2.51,3.94v55.39a4.36,4.36,0,0,1-2,3.66A4.27,4.27,0,0,1,44.94,126.44Zm93.27-52a1.29,1.29,0,0,0-.55,1.74,1.57,1.57,0,0,0,.24.32,8.91,8.91,0,0,1,2.77,6.46c0,4-3,6.91-4.52,8.08a.85.85,0,0,0-.17,1.19l.05.06a9.25,9.25,0,0,1,2.2,6.2c0,3.82-2.82,7-4.94,8.44a1.37,1.37,0,0,0-.38,1.86,13.06,13.06,0,0,1,1.77,6.79c0,5.32-5.32,9.54-10.43,9.54h-62a4.18,4.18,0,0,1-1.9-.45l-4.07-2c0-.17,0-.34,0-.52V66.68a11.45,11.45,0,0,0-.23-2.28l.16-.12a4.1,4.1,0,0,0,.64-.61C59.1,61.06,73.4,44,76.62,33.08a4.25,4.25,0,0,0,.16-1.18V14.33a5.85,5.85,0,0,1,2.37-4.7A12.43,12.43,0,0,1,86.6,7V7a12.88,12.88,0,0,1,6,1.61,4.3,4.3,0,0,1,1.61,1.5,60.75,60.75,0,0,1,7.11,17,16.56,16.56,0,0,1,.22,6.65L98,54.3a.73.73,0,0,0,.59.85h35.84a9,9,0,0,1,8.82,7,14.41,14.41,0,0,1,.31,3C143.56,70.72,140.38,73.29,138.21,74.39Z",
                          transform: "translate(0 0.02)",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.reactions.length) + " "),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }