import { getBars } from "@/api/barsRepository";

// initial state
const state = () => ({
  bars: null,
  barsPerRegions: null,
});

// getters
const getters = {
  getBars: (state) => {
    return state.bars ? state.bars : null;
  },
};

// actions
const actions = {
  getBars({ commit }) {
    console.log("about to get rooms");
    return new Promise((resolve, reject) => {
      getBars()
        .then((res) => {
          commit("updateBars", res.data);
          resolve("Got bars in store");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // updateTokens(userInfo) {
  //   // alert(userInfo.getters["getUserId"]);
  //   tokensRepository
  //     .getCredit(userInfo.getters["getUserId"])
  //     .then((response) => {
  //       userInfo.commit("mutateTokens", response.data);
  //     });
  // },
};

// mutations
const mutations = {
  updateBars(state, value) {
    console.log("mutation update bars");
    /*     if (state.bars) {
      if (state.bars.length !== value.length) {
        console.log(
          "all bars were reset in store because there are more or less bars"
        );
        state.bars = value;
        return;
      }
      for (let bar of value) {
        const barInMemory = isBarInMemory(state, bar);

        if (barInMemory) {
          if (barInMemory.updatedAt < bar.updatedAt) {
            const ind = state.bars.findIndex(
              (elem) => elem.id === barInMemory.id
            );
            state.bars[ind] = bar;
            console.log("bar", bar.id, "was updated");
          } else {
            console.log("bar doesnt need to be updated");
          }
        } else {
          state.bars.push(bar);
          console.log("bar were updated to store");
        }
      }
    } else { */
    state.bars = value;
    /*      console.log("all bars were added to store");
    } */
  },
};

function isBarInMemory(state, room) {
  const barInMemory = state.bars.find((bar) => bar.id === room.id);

  if (!barInMemory) return null;

  return barInMemory;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
