import httpClient from "@/helpers/httpClient";

const basePath = "user-settings/user";

export default {
  getSettings,

  updateSettings(id, body) {
    return httpClient().put(`${basePath}/${id}`, body);
  },
};

export function getSettings(id) {
  return httpClient().get(`${basePath}/${id}`);
}
