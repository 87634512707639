var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", [
    _vm.currentNotification
      ? _c("div", { staticClass: "notification-container" }, [
          _vm.currentNotification.contentType === "string"
            ? _c("div", [
                _c("div", { ref: "toast", staticClass: "toast" }, [
                  _c("img", {
                    attrs: { src: "/img/icons/NOTIFICATION_ICON.png", alt: "" },
                  }),
                  _c("p", [_vm._v(_vm._s(_vm.currentNotification.message))]),
                  _c("button", { on: { click: _vm.clearToast } }, [
                    _vm._v("×"),
                  ]),
                ]),
              ])
            : _vm.currentNotification.contentType === "html"
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.currentNotification.message),
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }