<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    :stroke-width="strokeWidth"
    :stroke="strokeColor"
    :class="[`w-${size} h-${size}`]"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15.75 19.5L8.25 12l7.5-7.5"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeft",

  props: {
    strokeColor: {
      type: String,
      default: "currentColor",
    },

    strokeWidth: {
      type: Number,
      default: 1.5,
    },

    size: {
      type: Number,
      default: 6,
    },
  },
};
</script>

<style></style>
