var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.keyboardHeight,
      ref: "messagesWrapper",
      staticClass: "messages-wrapper",
      style:
        _vm.keyboardHeight && _vm.taHeight
          ? `flex: 0 1 calc(100% - ${_vm.keyboardHeight}px - ${_vm.taHeight}px)`
          : null,
    },
    [
      _vm.msgs.length > 0 &&
      !_vm.isUserBanned &&
      _vm.isSystemEnabled &&
      _vm.isRoomEnabled
        ? _c(
            "div",
            { staticClass: "messages-container" },
            _vm._l(_vm.msgs, function (msg, index) {
              return _c("MessageBalloon", {
                key: msg.uuid ? msg.uuid : msg.from + "_" + msg.date,
                attrs: {
                  color: _vm.nicknameToHex[msg.from],
                  content: msg.content,
                  sent: msg.from === _vm.nickname,
                  nickname: msg.from,
                  img: _vm.getUpdatedUserImg(msg.from),
                  adminMessage: msg.admin,
                  sameSenderAsPreviousBalloon:
                    _vm.isSameSenderAsPreviousBalloon(msg, index),
                  lastSenderMessage: _vm.isLastSenderMessage(msg, index),
                  time: _vm.getTime(msg.date),
                  reactions: msg.reactions,
                },
                on: {
                  "double-click-detected": function ($event) {
                    return _vm.doubleClickDetectedHandler(msg)
                  },
                },
              })
            }),
            1
          )
        : !_vm.isRoomEnabled || !_vm.isSystemEnabled
        ? _c(
            "div",
            { staticClass: "w-full h-full flex justify-center items-center" },
            [
              _c("p", { staticClass: "w-fit text-gray-400" }, [
                _vm._v("La chat è attualmente disabilitata."),
              ]),
            ]
          )
        : _vm.isUserBanned
        ? _c(
            "div",
            { staticClass: "w-full h-full flex justify-center items-center" },
            [
              _c("p", { staticClass: "w-fit text-gray-400" }, [
                _vm._v("Sei stato bandito da questa stanza."),
              ]),
            ]
          )
        : !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "w-full h-full flex justify-center items-center" },
            [
              _c("p", { staticClass: "w-fit text-gray-400" }, [
                _vm._v(" Nessun messaggio è stato ancora inviato... "),
              ]),
            ]
          )
        : _vm.isLoading
        ? _c(
            "div",
            { staticClass: "w-full h-full flex justify-center items-center" },
            [
              _c("p", { staticClass: "w-fit text-gray-400" }, [
                _vm._v("Loading..."),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }