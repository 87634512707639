var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: _vm.isPWAInIphone ? "padding-bottom: 1.3rem" : "",
      attrs: { id: "user-info" },
    },
    [
      _c(
        "button",
        {
          staticClass: "user-avatar",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$router.push({ name: "Settings" })
            },
          },
        },
        [
          _vm.showAvatar
            ? _c("img", {
                attrs: { src: _vm.showAvatar, alt: "User avatar" },
                on: { error: _vm.replaceByDefault },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "h3",
            {
              staticClass:
                "py-2 uppercase text-primary text-sm font-medium text-center w-full lg:text-2xl truncate",
            },
            [_vm._v(" " + _vm._s(_vm.showNickname) + " ")]
          ),
          _c("WalletDisplay", { attrs: { hideCredit: _vm.hideCredit } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }