const state = () => ({
  showPwa: true,
});

const getters = {
  getShowPWA: (state) => state.showPwa,
};

const actions = {
  updateShowPwa({ commit }, showPwa) {
    commit("mutateShowPwa", showPwa);
  },
};

const mutations = {
  mutateShowPwa(state, showPwa) {
    state.showPwa = showPwa;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
