<template>
  <!-- :class="{ 'top-bar__fullscreen': isInFullscreen }" -->

  <div id="top-bar">
    <div
      @click="closeMenu"
      class="flex flex-1 min-w-0"
      :class="{
        'w-full justify-center': !$store.getters['auth/getIsAuthenticated'],
      }"
    >
      <button class="px-4 py-3 min-w-fit" @click.prevent="pushToHomeRoute">
        <img
          :src="chooseVrLogo[1]"
          alt="Virtual Rooms Logo Icon"
          :class="chooseVrLogo[0]"
        />
      </button>
      <p v-if="!!commitHash" class="text-xs text-gray-500">
        COMMIT: {{ commitHash }}
      </p>
      <!-- <h1
        class="barName text"
        v-if="barName && !minWidth834 && !gameIframeOpened"
      >
        {{ barName }}
      </h1> -->
      <a
        class="barName"
        v-if="barName && !minWidth834"
        @click.prevent="backToRoomClick"
      >
        {{ barName }}
      </a>
    </div>
    <div
      class="flex gap-x-1 h-full"
      style="width: 200px"
      v-if="$store.getters['auth/getIsAuthenticated']"
    >
      <WalletDisplay
        :hideTokens="true"
        :hideTitles="true"
        class="border-r-2 border-gray-700 pr-4"
      />

      <Chat
        :isInFullscreen="isInFullscreen"
        :isInGame="isInGame"
        :isSearchPopUpOpen="isSearchPopUpOpen"
        :keyboardHeight="keyboardHeight"
      />
      <!-- v-if="!isSearchPopUpOpen && !isRegionPopUpOpen" -->
      <!-- @fullscreenBtnVisibility="fullscreenBtnVisibilityHandler" -->

      <button
        v-if="$store.getters['auth/getIsAuthenticated']"
        class="menu-btn"
        @click.prevent="menuInteraction"
      >
        <div class="menu-btn--span-container">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import minWidthMixin834 from "@/mixins/minWidthMixin834.vue";
import minWidthMixin560 from "@/mixins/minWidthMixin560.vue";
import minWidthMixinXS from "@/mixins/minWidthMixinXS.vue";
import { mapState } from "vuex";
import WalletDisplay from "@/components/WalletDisplay";
import Chat from "@/chat/Chat.vue";

export default {
  name: "TopBar",

  data() {
    return {
      commitHash: null,
      barName: null,
      barSlug: null,
      gameIframeOpened: false,
      isMenuOpen: false,
    };
  },

  props: ["isInFullscreen", "isInGame", "isSearchPopUpOpen", "keyboardHeight"],

  computed: {
    ...mapState("deviceInfo", ["isIpad", "isStandalone"]),

    isAuthenticated() {
      return this.$store.getters["auth/getIsAuthenticated"];
    },

    chooseVrLogo() {
      console.log(this.$route.name);
      console.log(this.minWidth560);
      console.log(!!this.$route.name);
      if (
        !!this.$route.name == false ||
        this.$route.name === "Login" ||
        this.$route.name === "Auth" ||
        this.minWidth560
      ) {
        return ["w-14", "/img/VR_Logo.png"];
      } else {
        return ["w-5", "/img/VR_ICON.png"];
      }
    },
  },

  components: {
    SectionTitle,
    WalletDisplay,
    Chat,
  },

  mixins: [minWidthMixin834, minWidthMixinXS, minWidthMixin560],

  created() {
    this.commitHash = process.env.VUE_APP_COMMIT_VERSION;
    window.addEventListener("fullscreenchange", this.checkFullscreen);
    window.addEventListener("webkitfullscreenchange", this.checkFullscreen);
    this.$root.$on("barNameUpdate", this.barNameUpdateHandler);
    this.$root.$on("gameIframeOpened", this.gameIframeOpenedHandler);
    this.$root.$on("closeMenu", this.closeMenuHandler);
  },

  methods: {
    closeMenuHandler() {
      this.isMenuOpen = false;
    },

    menuInteraction() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        this.$root.$emit("openMenu");
        this.$root.$emit("closeChat");
      } else {
        this.$root.$emit("closeMenu");
      }
    },
    closeMenu() {
      this.$root.$emit("closeMenu");
    },
    pushToHomeRoute() {
      if (this.$route.name === "SlotsBar") {
        this.$router.go(0);
      } else {
        this.$router.push({ name: "SlotsBar" });
        this.closeMenuHandler();
        this.$root.$emit("closeMenu");
      }
    },
    backToRoomClick() {
      if (this.gameIframeOpened) {
        this.$router.push({
          name: "Bar",
          params: {
            barName: this.barSlug,
          },
        });
        this.$root.$emit("closeMenu");
        this.gameIframeOpened = false;
      } else {
        this.$root.$emit("closeEverythingInsideVRIframe");
      }
    },
    barNameUpdateHandler({ barName, barSlug }) {
      this.barName = barName;
      this.barSlug = barSlug;
    },
    gameIframeOpenedHandler(state) {
      this.gameIframeOpened = state;
    },

    callAction() {
      this.$store.dispatch("fullscreen/updateFullscreen");
    },
  },
};
</script>

<style lang="postcss" scoped>
#top-bar {
  height: 2.5rem;
  z-index: 3;
  @apply w-full bg-gray-900 fixed top-0 flex items-center justify-between gap-x-4;

  .menu-btn {
    /* padding-top: 0.75rem;
    padding-bottom: 0.75rem; */
    @apply py-3 pr-4 pl-2 outline-none group transform;

    .menu-btn--span-container {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      @apply w-5 h-4 px-2 relative cursor-pointer;
    }
  }

  @media (hover: hover) {
    .menu-btn:hover {
      @apply scale-105;

      span {
        box-shadow: 0 0 1px #ffffff;
      }
    }
  }

  .menu-btn span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .menu-btn span:nth-child(1) {
    top: 1px;
  }

  .menu-btn span:nth-child(2),
  .menu-btn span:nth-child(3) {
    top: 7px;
  }

  .menu-btn span:nth-child(4) {
    top: 13px;
  }

  .menu-btn.open span:nth-child(1) {
    top: 7px;
    opacity: 0;
  }

  .menu-btn.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .menu-btn.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .menu-btn.open span:nth-child(4) {
    top: 6px;
    opacity: 0;
  }

  /* @screen lg {
      height: 10vh;
   } */
  #logo {
    @apply px-2;
    /* @screen xs {
      @apply pr-0;
    } */
    /* @screen sm {
         @apply w-screen h-full flex items-center justify-center;
      }*/
  }

  .barName {
    @apply text-sm truncate px-2 my-auto min-w-0 text-gray-400 h-fit;
  }
  /* 
  .barName.text {
    @apply border-transparent text-gray-400 flex-1;
  }

  .barName.link {
    @apply rounded-full border-gray-500 h-fit;
  } */
}
</style>
