import httpClient from "@/helpers/httpClient";

const basePath = "rooms";

export function getBars() {
  return httpClient().get(`${basePath}`);
}

export function getBarsByRegion(selectedRegion) {
  return httpClient().get(`${basePath}/region/${selectedRegion}`);
}

export function getBarInfo(barName) {
  return httpClient().get(`${basePath}/${barName}`);
}

export function getBarImage(barName) {
  return httpClient().get(`${basePath}/${barName}/image`);
}

export function getBarFavicon(barName) {
  return httpClient().get(`${basePath}/${barName}/favicon`);
}
