// initial state
const state = () => ({
  fullscreen: false,
  fullscreenActivated: false,
});

//getters
const getters = {
  getFullscreen: (state) => state.fullscreen,
  getOldHeights: (state) => {
    return {
      landscape: state.oldHeightLandscape,
      portrait: state.oldHeightPortrait,
    };
  },
  getFullscreenActivated: (state) => {
    state.fullscreenActivated;
  },
};

// actions
const actions = {
  updateFullscreen({ commit }) {
    commit("mutateFullscreen");
  },
};

// mutations
const mutations = {
  mutateFullscreen(fullscreen) {
    this.fullscreen = fullscreen;
  },
  mutateFullscreenActivated(state, fullscreenActivated) {
    state.fullscreenActivated = fullscreenActivated;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
