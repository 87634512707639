// initial state
const state = () => ({
  map: null,
  chart: null,
});

// getters
const getters = {
  getMap: (state) => {
    return state.map;
  },
  getChart: (state) => {
    return state.chart;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  updateChart(state, value) {
    state.chart = value;
  },
  updateMap(state, value) {
    state.map = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
