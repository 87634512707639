import * as Cookies from "js-cookie";
import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";
import appSettings from "./modules/appSettings";
import auth from "./modules/auth";
import bars from "./modules/bars";
import deviceInfo from "./modules/deviceInfo";
import fullscreen from "./modules/fullscreen";
import map from "./modules/map";
import userInfo from "./modules/userInfo";
import socket from "./modules/socket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    displayIntroVideo: true,
    showCredit: true,
    skins: [],
  },
  getters: {
    getDisplayIntroVideo(state) {
      return state.displayIntroVideo;
    },
    getShowCredit(state) {
      return state.showCredit;
    },
  },
  actions: {},
  mutations: {
    updateDisplayIntroVideo(state, val) {
      state.displayIntroVideo = val;
    },
    updateShowCredit(state, val) {
      state.showCredit = val;
    },
    updateSkins(state, val) {
      console.log('ABOUT TO UPDATE SKINS');
      console.log(val);
      state.skins = val;
    }
  },

  modules: {
    appSettings,
    auth,
    userInfo,
    bars,
    map,
    fullscreen,
    deviceInfo,
    socket,
  },
  plugins: [
    persistedState({
      key: "auth",
      paths: ["auth"],
      storage: {
        getItem: (key) => {
          const cookieString = Cookies.get(key);
          if (!!cookieString) {
            const value = JSON.parse(cookieString);
            const deviceId = localStorage.getItem("deviceId");
            if (deviceId) {
              value.auth.deviceId = deviceId;
            }
            return JSON.stringify(value);
          }
        },
        setItem: (key, value) => {
          if (value !== 1 && !!value) {
            let val = value;
            let parsed = JSON.parse(value);
            let { deviceId, accessToken, refreshToken, ...rest } = parsed.auth;
            parsed.auth = rest;
            val = JSON.stringify(parsed);

            if (deviceId && deviceId != "null") {
              localStorage.setItem("deviceId", deviceId);
            }
            Cookies.set(key, val, { expires: 16 * 365, secure: false }); // in days
          }
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    persistedState({
      key: "at",
      paths: ["auth.accessToken"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          Cookies.set(key, value, {
            expires: parseInt(process.env.VUE_APP_AT_EXPIRATION_DAYS),
            secure: false,
          });
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    persistedState({
      key: "rt",
      paths: ["auth.refreshToken"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          Cookies.set(key, value, {
            expires: parseInt(process.env.VUE_APP_RT_EXPIRATION_DAYS),
            secure: false,
          });
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    persistedState({
      key: "app_settings",
      paths: ["appSettings"],
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
    persistedState({
      key: "display_intro_video",
      paths: ["displayIntroVideo"],
      storage: {
        getItem: (key) => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: (key) => sessionStorage.removeItem(key),
      },
    }),
    persistedState({
      key: "show_credit",
      paths: ["showCredit"],
      storage: {
        getItem: (key) => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: (key) => sessionStorage.removeItem(key),
      },
    }),
    persistedState({
      key: "userInfo",
      paths: ["userInfo"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          Cookies.set(key, value, {
            expires: 16 * 365,
            secure: false,
          });
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    persistedState({
      key: "bars",
      paths: ["bars"],
      storage: {
        getItem: (key) => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: (key) => sessionStorage.removeItem(key),
      },
    }),
  ],
});
