import httpClient from "@/helpers/httpClient";

const basePath = "credit";

export function getCredit(id) {
  if (
    process.env.VUE_APP_IS_SKIN_INTEGRATED &&
    process.env.VUE_APP_IS_SKIN_INTEGRATED == "true"
  ) {
    return httpClient().get(`${basePath}/${id}/balance`);
  }

  return Promise.resolve({
    data: {
      credit: 193.25,
    },
  });
}
