<template>
  <div>
    <MessagesContainer
      :keyboardHeight="keyboardHeight"
      :msgs="msgs"
      :scrollDown="triggerScroll"
      :scrollBehavior="scrollBehavior"
      :isUserBanned="isUserBanned"
      :isSystemEnabled="isSystemEnabled"
      :isRoomEnabled="isRoomEnabled"
      :taHeight="taHeight"
      :isLoading="isLoading"
      @newMessages="moreMessagesHandler"
      @reaction-update="reactionUpdateHandler"
    />
    <TypingArea
      v-if="!isUserBanned && isRoomEnabled && isSystemEnabled"
      :moreMessages="moreMessages"
      @new-messages-click="newMessagesClickHandler"
      @send-message="sendMessageHandler"
      @typingAreaHeight="typingAreaHeightHandler"
    />
    <!-- :users="users" -->
  </div>
</template>
<script>
import { init } from "@/chat/socket.js";
import { events } from "@/chat/helpers/socketEvents.js";
import MessagesContainer from "@/chat/components/messages/MessagesContainer.vue";
import TypingArea from "@/chat/components/messages/TypingArea.vue";
export default {
  name: "ChatBar",
  components: { MessagesContainer, TypingArea },

  props: {
    room: {
      type: String,
      required: true,
    },

    keyboardHeight: {
      type: Number,
      defaul: 0,
    },
  },

  data() {
    return {
      socket: null,
      nickname: this.$store.getters["userInfo/getNickname"],
      userId: this.$store.getters["auth/getUserId"],
      deviceId: this.$store.getters["auth/getDeviceId"],
      numContract: this.$store.getters["auth/getNumContract"],
      avatar: this.$store.getters["userInfo/getAvatar"],
      msgs: [],
      users: [],
      moreMessages: false,
      triggerScroll: false,
      scrollBehavior: "auto",
      isUserBanned: false,
      isSystemEnabled: true,
      isRoomEnabled: true,
      taHeight: null,
      isLoading: true,
    };
  },

  computed: {
    usersCount() {
      let uniqueUsernames = new Set();
      const users = this.users.filter((user) => {
        if (!uniqueUsernames.has(user.username)) {
          uniqueUsernames.add(user.username);
          return true;
        }
        return false;
      });
      return users.length;
    },
  },

  created() {
    this.socketInit();
  },

  activated() {
    this.scrollDown();
  },

  methods: {
    typingAreaHeightHandler(val) {
      this.taHeight = val;
    },

    socketInit() {
      const query = {
        room: this.room,
        username: this.nickname,
        numContract: this.numContract,
        image: this.avatar,
        userId: this.userId,
        deviceId: this.deviceId,
      };

      this.socket = init(query);
      this.socketHandlers();
    },

    socketHandlers() {
      this.socket.on("connect_error", (err) => {
        switch (err.data.event) {
          case events.SYSTEM_DISABLED:
            this.disableSystem();
            break;
          case events.ROOM_DISABLED:
            this.disableRoom();
            break;
          case events.USER_BANNED:
            this.banUser();
            break;
        }
      });

      this.socket.on(events.SESSION_INFO, (data) => {
        console.log("session:info", data);
      });

      this.socket.on(events.NEW_MESSAGE, (data) => {
        this.msgs.push(data);
        console.log("new:message", data);
      });

      this.socket.on(events.CHAT_USERS, (data) => {
        console.log("chat:users", data);
        this.users = data;
      });

      this.socket.on(events.CHAT_MESSAGES, (data) => {
        this.msgs = data.messages;
        this.isLoading = false;
        console.log("chat:messages", data.messages);
      });

      this.socket.on(events.USER_JOINED, (data) => {
        console.log("user:joined", data);
        this.users.push(data);
      });

      this.socket.on(events.USER_LEFT, (data) => {
        const userToRemove = this.users.findIndex(
          (user) => user.username == data.username
        );
        if (userToRemove !== -1) {
          this.users.splice(userToRemove, 1);
        }
        console.log("user:left", data);
      });

      this.socket.on(events.NEW_MESSAGE_REACTION, (data) => {
        const msgIndex = this.msgs.findIndex((msg) => msg.uuid === data.uuid);
        this.msgs[msgIndex].reactions.push(data);
        console.log("new:message:reaction", data);
      });

      this.socket.on(events.REMOVE_MESSAGE_REACTION, (data) => {
        console.log("remove:message:reaction", data);
        const msgIndex = this.msgs.findIndex((msg) => msg.uuid === data.uuid);
        if (msgIndex !== -1) {
          const reactionIndex = this.msgs[msgIndex].reactions.findIndex(
            (reaction) => reaction.by === data.by
          );
          if (reactionIndex !== -1) {
            this.msgs[msgIndex].reactions.splice(reactionIndex, 1);
          }
        }
      });

      this.socket.on(events.SYSTEM_DISABLED, () => this.disableSystem());

      this.socket.on(events.ROOM_DISABLED, () => this.disableRoom());

      this.socket.on(events.USER_BANNED, () => this.banUser());

      this.socket.connect();
    },

    disableRoom() {
      this.isRoomEnabled = false;
      this.$emit("change-room-status", "off");
      console.log("room:disabled");
      this.socket.disconnect();
    },
    disableSystem() {
      this.isSystemEnabled = false;
      console.log("system:disabled");
      this.socket.disconnect();
    },
    banUser() {
      this.isUserBanned = true;
      console.log("user:banned");
      this.socket.disconnect();
    },

    sendMessageHandler(data) {
      this.socket.emit(events.ROOM_MESSAGE, data);
    },

    reactionUpdateHandler(uuid) {
      const data = { messageUuid: uuid };
      this.socket.emit(events.ROOM_MESSAGE_REACTION, data);
    },

    scrollDown() {
      this.triggerScroll = true;
      setTimeout(() => {
        this.triggerScroll = false;
        this.moreMessages = false;
        this.scrollBehavior = "auto";
      }, 200);
    },

    newMessagesClickHandler() {
      this.scrollBehavior = "smooth";
      this.scrollDown();
    },

    moreMessagesHandler() {
      this.moreMessages = true;
      setTimeout(() => {
        this.moreMessages = false;
      }, 10000);
    },
  },

  destroyed() {
    this.socket.off("connect_error");
    this.socket.disconnect();
  },
};
</script>
<style lang="postcss" scoped></style>
