export default [
  {
    name: "galeopatra",
    src: "/img/avatars/galeopatra.png",
    bg: "#85251e",
  },
  {
    name: "genie",
    src: "/img/avatars/genie.png",
    bg: "#0e4701",
  },
  {
    name: "lei1",
    src: "/img/avatars/lei1.png",
    bg: "#4c2e66",
  },
  {
    name: "avatar1",
    src: "/img/avatars/avatar1.png",
    bg: "#a65712",
  },
];
