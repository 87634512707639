<template>
  <div class="modal">
    <div class="modal__overlay"></div>
    <div class="modal__container">
      <div class="header-body-container gradient-blue">
        <div class="modal-header">
          <h2>ricompensa giornaliera</h2>
        </div>

        <div class="modal-body">
          <div
            v-if="!showInfo"
            class="daily-reward"
            :class="{ 'fade-out': isClaimed }"
          >
            <h3
              class="text-lg xl:text-xl uppercase font-medium tracking-wide text-center"
            >
              Congratulazioni!
            </h3>
            <p class="text-center text-sm xl:text-base">
              Hai vinto una ricompensa giornaliera.
            </p>

            <div class="flex items-center justify-center p-6">
              <img v-if="!isClaimed" src="/img/treasure_closed.png" alt="" />
              <div v-else class="relative">
                <img
                  src="/img/treasure_open.png"
                  alt=""
                  class="opening-box golden-drop-shadow-animation"
                />
                <span
                  class="tokens-container flex items-center golden-drop-shadow-animation"
                >
                  <p class="text-4xl font-bold">{{ tokensEarned }}</p>
                  <Coin style="transform: scale(0.35); margin-left: -20px" />
                </span>
              </div>
            </div>
          </div>
          <div v-else class="daily-rewards-info fade-in">
            <div class="flex w-full gap-x-4 items-center">
              <div
                class="grid box-border gap-4 min-w-0"
                :class="`grid-cols-${Math.floor(
                  data.rules.consecutiveRewardDays / 2
                )}`"
                :style="`flex: ${Math.floor(
                  data.rules.consecutiveRewardDays / 2
                )}; grid-template-columns: repeat(${Math.floor(
                  data.rules.consecutiveRewardDays / 2
                )}, minmax(0, 1fr));`"
              >
                <div
                  v-for="i in data.rules.consecutiveRewardDays - 1"
                  :key="i"
                  class="rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden relative"
                  :class="{
                    'blue-shadow-pulsing shine':
                      i === dailyRewardData.consecutive,
                  }"
                >
                  <p
                    class="uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2"
                  >
                    {{
                      i === dailyRewardData.consecutive ? "Oggi" : `Giorno ${i}`
                    }}
                  </p>
                  <img
                    src="/img/treasure_open.png"
                    class="mt-4 w-2/3 mx-auto"
                    :class="{
                      'saturate-25':
                        i !== dailyRewardData.consecutive && isRewardClaimed(i),
                      'golden-drop-shadow-animation':
                        i === dailyRewardData.consecutive && isRewardClaimed(i),
                    }"
                    alt=""
                  />
                  <!-- 'saturate-75': !isRewardClaimed(i), -->
                  <!-- :style="
                      i !== dailyRewardData.consecutive && isRewardClaimed(i)
                        ? 'filter: saturate(0.25)'
                        : 'filter: drop-shadow(0 0 6px #fcc201);'
                    " -->
                  <!-- :style="treasureFilter" -->
                  <!-- box-shadow: 0 0 10px 6px #fcc201; -->
                  <img
                    v-if="isRewardClaimed(i)"
                    class="absolute top-1.5 right-2 w-4 h-4"
                    src="/img/icons/CHECK_ICON.png"
                    alt=""
                    :class="{
                      'todays-check': i === dailyRewardData.consecutive,
                    }"
                  />
                  <!-- flex justify-between items-center  -->

                  <div
                    class="mt-4 gradient-blue-30 px-2 py-0.5"
                    :class="{
                      'saturate-25':
                        i !== dailyRewardData.consecutive && isRewardClaimed(i),
                    }"
                  >
                    <p
                      class="flex text-sm xl:text-base font-bold gap-x-1 items-center justify-center"
                      v-if="
                        !isRewardClaimed(i) || i === dailyRewardData.consecutive
                      "
                    >
                      +{{ dailyRewardData.amount }}
                      <span
                        ><img
                          src="/img/golden-token.svg"
                          alt=""
                          class="h-4 w-4"
                      /></span>
                    </p>
                    <p
                      v-else
                      class="text-xs xl:text-base tracking-wide text-center uppercase tracking-wide"
                    >
                      Richiesto
                    </p>
                  </div>
                </div>
                <div
                  v-if="isEven"
                  class="flex-1 h-fit min-w-0 rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden flex flex-col justify-center relative"
                  :class="{
                    'blue-shadow-pulsing shine': consRewardData,
                  }"
                >
                  <img
                    v-if="consRewardData"
                    class="absolute top-1.5 right-2 w-4 h-4"
                    src="/img/icons/CHECK_ICON.png"
                    alt=""
                    :class="{
                      'todays-check': i === dailyRewardData.consecutive,
                    }"
                  />
                  <p
                    class="uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2"
                  >
                    {{
                      dailyRewardData.consecutive ===
                      data.rules.consecutiveRewardDays
                        ? "Oggi"
                        : `Giorno ${data.rules.consecutiveRewardDays}`
                    }}
                  </p>
                  <div class="flex-1 flex flex-col justify-center mt-6">
                    <img
                      src="/img/treasure-chest.png"
                      class="w-2/3 mx-auto"
                      :class="{
                        'golden-drop-shadow-animation': consRewardData,
                      }"
                      alt=""
                    />

                    <div class="w-full h-10 gradient-blue-30 mt-6">
                      <div
                        class="tokens-container flex items-center -mt-8 w-fi"
                        style="transform: scale(0.25); margin-left: -2.3em"
                      >
                        <p class="text-8xl font-bold pt-0.5 mr-6">
                          <span class="mr-2.5">+</span
                          >{{
                            data.rules.consecutiveRewardTokens +
                            data.rules.dailyRewardTokens
                          }}
                        </p>
                        <img
                          src="/img/golden-token.svg"
                          alt=""
                          class="h-4 w-4"
                        />
                        <Coin />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!isEven"
                class="flex-1 h-fit min-w-0 rounded-xl bg-gray-900 bg-opacity-40 overflow-hidden flex flex-col justify-center relative"
                :class="{
                  'blue-shadow-pulsing shine': consRewardData,
                }"
              >
                <img
                  v-if="consRewardData"
                  class="absolute top-1.5 right-2 w-4 h-4"
                  src="/img/icons/CHECK_ICON.png"
                  alt=""
                  :class="{
                    'todays-check': i === dailyRewardData.consecutive,
                  }"
                />
                <p
                  class="uppercase font-semibold text-xs xl:text-base tracking-wide mt-2 px-2"
                >
                  {{
                    dailyRewardData.consecutive ===
                    data.rules.consecutiveRewardDays
                      ? "Oggi"
                      : `Giorno ${data.rules.consecutiveRewardDays}`
                  }}
                </p>
                <div class="flex-1 flex flex-col justify-center mt-6">
                  <img
                    src="/img/treasure-chest.png"
                    class="w-2/3 mx-auto"
                    :class="{ 'golden-drop-shadow-animation': consRewardData }"
                    alt=""
                  />

                  <div class="w-full h-10 gradient-blue-30 mt-6">
                    <div
                      class="tokens-container flex items-center justify-center"
                    >
                      <!-- style="transform: scale(0.25); margin-left: -2.3em" -->
                      <!-- <p class="text-8xl font-bold pt-0.5 mr-6"> -->
                      <p class="text-xl font-bold pt-0.5 mr-3">
                        <span class="mr-1">+</span
                        >{{
                          data.rules.consecutiveRewardTokens +
                          data.rules.dailyRewardTokens
                        }}
                      </p>
                      <!-- <Coin /> -->
                      <img
                        src="/img/golden-token.svg"
                        alt=""
                        class="h-7 w-7 mt-1.5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button v-if="showCloseModalBtn" @click="claimReward" class="claim-btn">
          Richiesta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Coin from "@/components/Coin";

export default {
  name: "DailyRewardModal",

  data() {
    return {
      dailyRewards: [],
      tokensEarned: 0,
      isClaimed: false,
      showInfo: false,
      dailyRewardData: null,
      consRewardData: null,
      pulsatingToday: false,
      isEven: false,
      showCloseModalBtn: false,
    };
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: { Coin },

  created() {
    if (this.data.rules.consecutiveRewardDays % 2 === 0) {
      this.isEven = true;
    }
    this.dailyRewardData = this.data.rewards.find(
      (reward) => reward.type == "daily reward"
    );
    this.consRewardData = this.data.rewards.find(
      (reward) => reward.type == "consecutive reward"
    );

    this.tokensEarned += this.data.rules.dailyRewardTokens;

    setTimeout(() => {
      this.openReward();
    }, 1500);
  },

  methods: {
    isRewardClaimed(day) {
      return day <= this.dailyRewardData.consecutive;
    },

    openReward() {
      this.isClaimed = true;
      setTimeout(() => {
        this.showInfo = true;
        this.showCloseModalBtn = true;
      }, 3500);
    },

    claimReward() {
      this.$store.dispatch("userInfo/updateTokens");
      this.$store.dispatch("socket/emitAckReward");
      this.$emit("close");
    },
  },
};
</script>

<style lang="postcss" scoped>
.gradient-blue {
  background: rgb(5, 6, 41);
  background: -moz-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    translateY rgba(21, 60, 115, 1) 75%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
  background: linear-gradient(
    0deg,
    rgba(5, 6, 41, 1) 25%,
    rgba(21, 60, 115, 1) 75%
  );
}

.modal {
  @apply flex items-center justify-center h-full w-full fixed top-0 left-0 z-50;

  .modal__overlay {
    @apply bg-gray-900 bg-opacity-70 h-full w-full absolute;
  }

  .modal__container {
    width: 90%;
    height: fit-content;
    max-height: 100%;
    @apply relative;

    @screen md {
      width: 60%;
    }

    @screen xl {
      width: 40%;
    }

    .header-body-container {
      /* -moz-box-shadow: 0 0 5px 0px #26c6da;
      -webkit-box-shadow: 0 0 5px 0px #26c6da;*/
      box-shadow: 0 0 5px 0px #26c6da;
      /* animation: pulse 0.5s infinite; */
      /* border border-primary */
      @apply rounded-xl overflow-hidden;
      .modal-header {
        @apply w-full;

        h2 {
          background: -moz-linear-gradient(
            0deg,
            rgba(14, 13, 75, 1) 25%,
            #26c6da 75%
          );
          background: -webkit-gradient(
            linear,
            160deg,
            color-stop(35%, rgba(14, 13, 75, 1)),
            color-stop(100%, #26c6da)
          );
          background: linear-gradient(
            160deg,
            rgba(14, 13, 75, 1) 35%,
            #26c6da 100%
          );
          @apply text-xl uppercase font-semibold tracking-wide text-center py-2;

          @screen md {
            @apply text-2xl;
          }
        }
      }

      .modal-body {
        @apply px-4 pt-4 pb-12;

        .daily-reward {
          opacity: 1;
          transition: all 0.5s ease-out;
          transition-delay: 3s;
          transform: scale(1);

          &.fade-out {
            opacity: 0;
            transform: scale(0.25);
          }

          img {
            @apply w-44 h-44;

            &.opening-box {
              animation: scale-up 0.45s ease-in-out;
              @apply w-44 h-44;
            }

            &:not(.opening-box) {
              animation: shake 0.5s;
              animation-iteration-count: infinite;
            }
          }

          .tokens-container {
            position: absolute;
            top: 20px;
            left: 3.1em;
            animation: getting-out 1s ease-in-out;
            /* animation: pulse 1s infinite; */
            /* filter: drop-shadow(0 0 5px #f0b800); */
            transform: translateY(8.5em);

            /* p {
              text-shadow: 0 0 30px #f0b800;
            } */
          }
        }

        .daily-rewards-info {
          opacity: 0;
          transition: opacity 0.5s ease-out;

          &.fade-in {
            opacity: 1;
          }
        }
      }
    }

    .modal-footer {
      @apply flex items-center justify-center relative;
      .claim-btn {
        background: -moz-linear-gradient(0deg, #0e0d4b 25%, #26c6da 75%);
        background: -webkit-gradient(
          linear,
          160deg,
          color-stop(35%, #0e0d4b),
          color-stop(100%, #26c6da)
        );
        background: linear-gradient(160deg, #0e0d4b 35%, #26c6da 100%);
        box-shadow: 0 0 10px 65px #26c6da;
        animation: pulse 1s infinite;
        border: 1px solid rgba(38, 198, 218, 0.7);
        @apply px-8 py-1.5 text-lg font-semibold tracking-wide rounded-full flex gap-x-1 -mt-2 absolute uppercase;

        @screen md {
          @apply text-2xl;
        }
      }
    }

    .gradient-blue-30 {
      background: -moz-linear-gradient(-30deg, #0e0d4b 25%, #26c6da 75%);
      background: -webkit-gradient(
        linear,
        -30deg,
        color-stop(35%, #0e0d4b),
        color-stop(100%, #26c6da)
      );
      background: linear-gradient(-30deg, #0e0d4b 35%, #26c6da 100%);
    }

    .blue-shadow-pulsing {
      box-shadow: 0 0 5px 0px #26c6da;
      animation: pulse 1s infinite;
      /* animation-iteration-count: 3; */
      border: 1px solid rgba(38, 198, 218, 0.7);
    }

    .todays-check {
      animation: poping-up 0.5s;
    }

    .shine {
      animation: shining 1s infinite;
      /* animation-iteration-count: 2; */
    }

    .saturate-25 {
      filter: saturate(0.25);
    }

    .golden-drop-shadow-animation {
      filter: drop-shadow(0 0 7px #f0b800);
      animation: golden-pulse 1s infinite;
      /* animation-iteration-count: 4; */
    }

    @keyframes shining {
      0% {
        filter: brightness(0.95);
      }
      50% {
        filter: brightness(1.15);
      }
      100% {
        filter: brightness(0.95);
      }
    }

    @keyframes poping-up {
      0% {
        transform: scale(0);
      }
      77% {
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes getting-out {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      41% {
        transform: scale(0.4);
        transform: translateY(-0.5em);
      }
      80% {
        transform: scale(1) translateY(8.5em);
      }
      92% {
        transform: translateY(7.5em);
      }
      100% {
        transform: translateY(8.5em);
      }
    }

    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      }
      50% {
        -webkit-box-shadow: 0 0 5px 0 #26c6da;
      }
      100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      }
    }

    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 5px 0px #26c6da;
        box-shadow: 0 0 5px 0px #26c6da;
      }
      50% {
        -moz-box-shadow: 0 0 8px 2px #26c6da;
        box-shadow: 0 0 8px 2px #26c6da;
      }
      100% {
        -moz-box-shadow: 0 0 5px 0 #26c6da;
        box-shadow: 0 0 5px 0 #26c6da;
      }
    }

    @keyframes shake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }

    @keyframes scale-up {
      0% {
        transform: scale(0.6);
        -webkit-transform: scale(0.6);
      }
      70% {
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }

    @keyframes golden-pulse {
      0% {
        filter: drop-shadow(0 0 6px #f0b800);
      }
      50% {
        filter: drop-shadow(0 0 9px #f0b800);
      }
      100% {
        filter: drop-shadow(0 0 6px #f0b800);
      }
    }
  }
}
</style>
