var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pwaAndroid bubble" }, [
    _c("div", {
      staticClass: "pwaAndroid__overlay",
      on: {
        click: function ($event) {
          return _vm.$emit("closePWAModal")
        },
      },
    }),
    _c(
      "button",
      {
        staticClass: "text-xl absolute right-1 -top-2 p-2",
        staticStyle: { filter: "drop-shadow(0 1px 3px rgb(0 0 0 / 1))" },
        on: {
          click: function ($event) {
            return _vm.$emit("closePWAModal")
          },
        },
      },
      [_vm._v(" × ")]
    ),
    _c("div", { staticClass: "w-full flex flex-col items-center" }, [
      _c("img", {
        staticClass: "w-16 rounded-xl mx-auto",
        attrs: {
          src: "/pwa/images/icons/icon-144x144.png",
          alt: "Virtual Rooms Logo",
        },
      }),
      _c("p", { staticClass: "w-fit text-lg mt-1 mb-3" }, [
        _vm._v("Virtual Rooms"),
      ]),
      _c("p", { staticClass: "mt-2 text-sm" }, [
        _vm._v(
          " Per aggiungere Virtual Rooms alla schermata iniziale clicca: "
        ),
      ]),
      _c(
        "button",
        {
          staticClass: "h-14",
          on: {
            click: function ($event) {
              return _vm.installApp()
            },
          },
        },
        [_c("PlayStoreBadgeB")],
        1
      ),
      _c("div", { staticClass: "ml-auto mt-4 text-sm" }, [
        _c(
          "button",
          {
            staticClass:
              "px-3 pt-1 pb-0.5 uppercase text-xs tracking-wide font-medium text-white border-b border-white",
            on: {
              click: function ($event) {
                return _vm.dontShowPWAModalAgain()
              },
            },
          },
          [_vm._v(" Non vedere più ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }