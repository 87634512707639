<template>
  <div class="spinningasset coin">
    <div>
      <div></div>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <em></em>
      <em></em>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coin",
};
</script>

<style lang="postcss" scoped>
/*----------------------------*/
/* SPINNING OBJECTS */
/*----------------------------*/

@keyframes brightness {
  0%,
  50%,
  100% {
    filter: blur(0px) brightness(120%)
      drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
      drop-shadow(0 0 5px rgba(255, 255, 255, 0.075))
      drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
      drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
  25%,
  75% {
    filter: blur(1px) brightness(50%)
      drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
      drop-shadow(0 0 5px rgba(255, 255, 255, 0.075))
      drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
      drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
  }
}

@keyframes spin {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
.spinningasset {
  text-align: left;
  transition: all 0.4s ease-out;
  cursor: pointer;
  animation: brightness 2.5s infinite linear;
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    width: 11px;
    margin: auto;
    background-size: 100px 100%;
    background-position: center;
  }
  > div {
    position: relative;
    z-index: 2;
    perspective: 10000px;
    transform-style: preserve-3d;
    transform-origin: center;
    animation: spin 2s infinite linear;
    > * {
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: inherit;
      background-size: cover;
    }

    > div:first-child {
      transform: translateZ(-6px);
    }
    > div:last-child {
      transform: translateZ(6px);
      background-image: url(/img/golden-token.svg);
    }
    em {
      filter: blur(0.5px);
      &:first-of-type {
        transform: translateZ(0px) rotateY(-6deg);
      }
      &:last-of-type {
        transform: translateZ(0px) rotateY(6deg);
      }
    }
    i {
      filter: blur(0.5px);
      &:nth-of-type(1) {
        transform: translateZ(-5px);
      }
      &:nth-of-type(2) {
        transform: translateZ(-4px);
      }
      &:nth-of-type(3) {
        transform: translateZ(-3px);
      }
      &:nth-of-type(4) {
        transform: translateZ(-2px);
      }
      &:nth-of-type(5) {
        transform: translateZ(-1px);
      }
      &:nth-of-type(6) {
        transform: translateZ(0px);
      }
      &:nth-of-type(7) {
        transform: translateZ(1px);
      }
      &:nth-of-type(8) {
        transform: translateZ(2px);
      }
      &:nth-of-type(9) {
        transform: translateZ(3px);
      }
      &:nth-of-type(10) {
        transform: translateZ(4px);
      }
      &:nth-of-type(11) {
        transform: translateZ(5px);
      }
    }
  }

  &.coin {
    > div {
      width: 100px;
      height: 100px;
      > div:first-child {
        background-image: url(/img/golden-token.svg);
      }
      > div:last-child,
      &::after,
      i,
      em {
        background-image: url(/img/golden-token.svg);
      }
    }
  }
}
</style>
